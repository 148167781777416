﻿import { ITimeSerieProvider } from "../TimeSerie/Abstraction/ITimeSerieProvider";
import { createNode, INode, INodeFilter, ITypedState, registerType } from "../WebApp";
import { IProjectItemState, ProjectItem } from "./ProjectItem";

export interface ISerieProviderItemState extends IProjectItemState {

    provider?: ITimeSerieProvider | ITypedState<ITimeSerieProvider>;
}

export class SerieProviderItem extends ProjectItem<ISerieProviderItemState> {

    constructor(state?: ISerieProviderItemState) {
        super(state);
    }

    async *childNodesAsync(_filter?: INodeFilter): AsyncIterable<INode> {

        if (this.provider != null) {

            const serieSet = await this.provider.getSeriesAsync();

            for (const serie of serieSet.series)
                yield createNode(this, serie, "provider", "TimeSerie");
        }
    }

    readonly nodeTypes = ["ProjectItem", "SerieProviderItem"];

    provider: ITimeSerieProvider;
}


registerType(SerieProviderItem, "SerieProviderItem");