﻿import { ISerieDimension, ISerieDimensionValue } from "../../TimeSerie/Abstraction/ITimeSerieInfo";
import { DimensionAggregtionMode, IDimensionFilter } from "../../TimeSerie/Providers/BasicSerieProvider";
import { IRefreshable, IView, SelectableItemView, BaseItemEditor, getTypeName, RemovableItemView, registerType, Container, Panel, Editors, MultiItemSelector, staticItems, linq, EditableItemsSource, MultiItemPicker, Format } from "../../WebApp";

export interface ISerieDimensionConfig  {

    dimension: ISerieDimension;

    onValueChanged?: () => void;
}


export class DimensionFilterEditor extends BaseItemEditor<IDimensionFilter>  {

    protected _isEditing = 0;
    protected _selector: IRefreshable & IView;

    constructor(config: ISerieDimensionConfig) {
        super();

        this.value = {
            dimensionName: config.dimension.name,
            mode: "sum",
            values: [],
            excluded: []
        };

        this.view = new Container<Panel>({
            ...config,
            name: getTypeName(this),
            title: config.dimension.name,
            content: new Panel(),
        });

        if (config.onValueChanged)
            this.prop("value").subscribe(() => config.onValueChanged());

        this.dimension = config.dimension;

        this.aggregationMode = "serie";

        this.view.content.addView(Editors.singleItemSelector({
            allowNull: false,
            itemsSource: staticItems<DimensionAggregtionMode>([
                { text: "add-serie", value: "serie"},
                { text: "sum", value: "sum" },
            ], false),
            value: this.prop("aggregationMode")
        }));

        const itemsSource = new EditableItemsSource<ISerieDimensionValue, number>({
            getItemsAsync: async filter => linq(this.dimension.values).where(a => !filter || a.displayName.toLowerCase().indexOf(filter) != -1).orderBy(a => a["value"] || a["from"] || a.displayName).toArray(),
            getFilter: text => text?.toLowerCase(),
            getItemText: a => Format.title(a.displayName),
            getItemValue: a=> a.id
        });

        if (this.dimension.values.length < 20) {
            this.view.canExpand = this.dimension.values.length > 5;
            this.view.isExpanded = !this.view.canExpand;
            this._selector = this.view.content.addView(new MultiItemSelector({
                template: "ItemsView",
                showSelectAll: true,
                itemsSource: itemsSource,
                createItemView: item => {
                    const view = new SelectableItemView({
                        item: item.id,
                        content: new RemovableItemView({
                            content: item.displayName,
                            isRemoved: this.excludedDimensions.indexOf(item.id) != -1,
                            removeAsync: async isRemoved => {

                                const foundIndex = this.excludedDimensions.indexOf(item.id);

                                if (isRemoved && foundIndex == -1)
                                    this.excludedDimensions.push(item.id);

                                if (!isRemoved && foundIndex != -1)
                                    this.excludedDimensions.splice(foundIndex, 1);

                                this.commitAsync()
                            }
                        })
                    });

                    return view;
                },
                value: this.prop("selectedDimensions")
            }));
        }
        else {
            this.view.canExpand = false;
            this.view.isExpanded = true;
            this.view.content.addView(new MultiItemPicker({
                itemsSource: itemsSource,
                value: this.prop("selectedDimensions")
            }));
        }

        this.prop("selectedDimensions").subscribe(() => this.commitAsync());

        this.prop("aggregationMode").subscribe(() => this.commitAsync());
    }

    /****************************************/

    async beginEditAsync(item: IDimensionFilter): Promise<void> {

        this._isEditing++;

        this.excludedDimensions = item.excluded ?? [];

        this.aggregationMode = item.mode;

        this.selectedDimensions = item.values;

        if (this._selector)
            await this._selector.refreshAsync();

        this.value = item;

        this._isEditing--;
    }

    /****************************************/

    async commitAsync(force?: boolean): Promise<boolean> {

        if (this._isEditing)
            return;

        this.value = {
            dimensionName: this.dimension.name,
            mode: this.aggregationMode,
            values: this.selectedDimensions,
            excluded: this.excludedDimensions
        }

        return true;
    }

    /****************************************/

    readonly view: Container<Panel>;

    readonly isValid = true;

    value: IDimensionFilter = null;

    aggregationMode: DimensionAggregtionMode = "serie";

    selectedDimensions: number[] = [];

    excludedDimensions: number[] = [];

    readonly dimension: ISerieDimension;

}

registerType(DimensionFilterEditor, "DimensionFilterEditor");