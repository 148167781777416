﻿import "AppTemplates/ChartStats.html"
import { linq, registerType, Services, ViewComponent } from "../../WebApp";
import { IChart } from "../Chart/Abstraction/IChart";
import { IChartSerie } from "../Chart/Abstraction/IChartSerie";


export interface IChartSerieStat {
    name: string;
    color: string;
    min: { x: string, y: string };
    max: { x: string, y: string };
    avg: string;
    sum: string;
    diff: string;
}

export class ChartStatsView extends ViewComponent  {

    constructor() {
        super({
            template: "ChartStats"
        });
        
    }

    update() {

        this.items = linq(Services.activeChart.series)
            .select(a => this.getSerieStats(a, Services.activeChart))
            .where(a=> a != null)
            .toArray();

        const xAxis = Services.activeChart.xAxis;
        const metrics = Services.activeChart.metrics.x;

        this.title = "Totali dal '" + xAxis.formatValue(xAxis.numberToValue(metrics.min)) + "' al '" + xAxis.formatValue(xAxis.numberToValue(metrics.max)) + "'";
    }

    protected getSerieStats(serie: IChartSerie<Date>, chart: IChart<Date>): IChartSerieStat{

        const stats = {
            max: { y: Number.NEGATIVE_INFINITY, x: null },
            min: { y: Number.POSITIVE_INFINITY, x: null },
            sum: 0,
            count: 0,
            first: null,
            last: null
        };

        for (const value of serie.values) {
            const curX = chart.xAxis.valueToNumber(value.x);
            const curY = chart.yAxis.valueToNumber(value.y);
            if (chart.metrics.x.min !== undefined && curX < chart.metrics.x.min)
                continue;
            if (chart.metrics.x.max !== undefined && curX > chart.metrics.x.max)
                break;

            if (stats.first === null)
                stats.first = curY;

            stats.last = curY;
            stats.count++;
            stats.sum += curY;
            if (curY < stats.min.y) {
                stats.min = {
                    y: curY,
                    x: value.x
                };
            }
            if (curY > stats.max.y) {
                stats.max = {
                    y: curY,
                    x: value.x
                };
            }
        }

        if (stats.count == 0)
            return null;

        return {
            avg: chart.yAxis.formatValue(stats.sum / stats.count),
            diff: chart.yAxis.formatValue(stats.last - stats.first),
            min: {
                y: chart.yAxis.formatValue(stats.min.y),
                x: chart.xAxis.formatValue(stats.min.x),
            },
            max: {
                y: chart.yAxis.formatValue(stats.max.y),
                x: chart.xAxis.formatValue(stats.max.x),
            },
            sum: chart.yAxis.formatValue(stats.sum),
            color: serie.style.borderColor,
            name: serie.name
        };
    }

    title: string = null;

    items: IChartSerieStat[] = [];
}

registerType(ChartStatsView, "ChartStatsView");