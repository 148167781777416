﻿import { ITimeSerie } from "../Abstraction/ITimeSerie";
import { ITimeSerieFilter } from "../Abstraction/ITimeSerieFilter";
import { ISerieInfo } from "../Abstraction/ITimeSerieInfo";
import { ITimeSerieSource } from "../Abstraction/ITimeSerieSource";
import { ITimeSerieValue } from "../Abstraction/ITimeSerieValue";
import { sha256 } from "../../Utils";
import { Http, HttpException, IDictionary, linq, Uri } from "../../WebApp";
import { IApiResult } from "../../Services/Api";


async function httpVersionAsync(url: string) : Promise<string>{

    return new Promise((res, rej) => {

        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = ev => {

            if (xmlhttp.readyState == XMLHttpRequest.DONE) {
                if (xmlhttp.status == 200) {

                    res(xmlhttp.getResponseHeader("etag"));
                }
                else
                    rej(new HttpException({ url, method: "HEAD" }, xmlhttp.status));
            }

        }

        xmlhttp.open("HEAD", Uri.absolute(url), true);
        xmlhttp.setRequestHeader("Content-Type", "application/json");
        xmlhttp.send("null");
    });
}

export class RemoteSerieSource implements ITimeSerieSource {

    protected _serieInfo: IDictionary<ISerieInfo>;

    constructor() {

    }

    /****************************************/

    async listSeriesAsync() : Promise<ISerieInfo[]> {

        const result = await Http.getJsonAsync<IApiResult<ISerieInfo[]>>("/api/serie/list");
        if (result.error)
            throw result.error;

        this._serieInfo = linq(result.result)
            .foreach(a => a.source = this)
            .toDictionary(a => a.name);

        return result.result;
    }

    /****************************************/

    async getSerieInfoAsync(name: string) {

        if (!this._serieInfo)
            await this.listSeriesAsync();

        return this._serieInfo[name];
    }

    /****************************************/

    async getSerieAsync(name: string, filter?: ITimeSerieFilter): Promise<ITimeSerie> {

        const url = "/api/serie/" + name + "/values";

        const version = await httpVersionAsync(url);

        console.log("Version: ", version);

        const resId = await sha256(name + "|" + JSON.stringify(filter));

        const cache = await caches.open("Serie");
        const cacheId = "https://serie/" + resId;
        const response = await cache.match(cacheId);

        if (response) {
            const etag = response.headers.get("etag");
            console.log("Cache found, etag: ", etag);
            if (etag == version) 
                return {
                    name: name,
                    values: await response.json(),
                    source: this
                };
        }

        const result = await Http.postJsonAsync<IApiResult<ITimeSerieValue[]>>(url, filter);
        if (result.error)
            throw result.error;

        await cache.put(cacheId, new Response(JSON.stringify(result.result), { headers: [["etag", version]] }));

        return {
            name: name,
            values: result.result,
            source: this
        };
    }

    /****************************************/

    async updateSerieAsync(name: string): Promise<boolean> {

        const result = await Http.getJsonAsync<IApiResult<boolean>>("/api/serie/" + name + "/update");
        if (result.error)
            throw result.error;
        return result.result;
    }


    readonly name: string = "Remote";
}
