﻿import { Project } from "../Project/Project";
import { ITypedState, Services, uuidv4, INodeResolver, INodeRef } from "../WebApp";

export class ProjectManager implements INodeResolver<Project> {

    constructor() {

        Services.nodeManager.registerRoot(this);
    }

    /****************************************/

    async resolveReferenceAsync(node: INodeRef) {

        if (node.type == "Project" && node.id == this.activeProject.id)
            return this.activeProject;
    }

    /****************************************/

    newProject() {

        this.activeProject = new Project();
        this.activeProject.id = uuidv4();
        this.activeProject.name = "New Project";

        return this.activeProject;
    }

    /****************************************/

    async saveProjectAsync() {

        localStorage.setItem("project", JSON.stringify(this.activeProject?.getState()));
    }

    /****************************************/

    async loadProjectAsync() {

        let state: ITypedState;

        const params = new URLSearchParams(window.location.search);

        if (params.has("project"))
            state = await Services.serverStorage.getItem<ITypedState>(params.get("project"));
        else {

            const json = localStorage.getItem("project");
            state = JSON.parse(json);
        }

        try {
            const project = new Project(state);
            this.activeProject = project;
            return project;
        }
        catch (ex) {
            console.log(ex);
        }
    }

    /****************************************/

    activeProject: Project;

    readonly globalId = "datalab";
}