﻿import { IHTMLAttach, registerType, ViewComponent, Editors, newProp, ObjectEditor } from "../../WebApp";
import { TimeChartAxis } from "../Chart/Axis/TimeChartAxis";
import { Chart } from "../Chart/Chart";
import "AppTemplates/ChartView.html"

interface IChartControl {
    yMin?: number;
    yMax?: number;
    yAuto: boolean;
}

export class ChartView extends ViewComponent implements IHTMLAttach {

    constructor() {
        super({
            template: "ChartView"
        })

        this.control = new ObjectEditor<IChartControl>({
            value: newProp<IChartControl>("controlValue", {yAuto: true }),
            styles: ["horizontal"],
            onPropertyChanged: () => {
                const value = this.control.value;
                this.chart.yAxis.max = null;
                this.chart.yAxis.min = null;

                if (!value.yAuto) {
                    if (value.yMin !== undefined)
                        this.chart.yAxis.min = value.yMin;
                    if (value.yMax !== undefined)
                        this.chart.yAxis.max = value.yMax;
                    this.chart.yAxis.rangeMode = "manual";
                }
                else
                    this.chart.yAxis.rangeMode = "window";

                this.chart.updateAsync();
            },
            commitMode: "onchange",
            properties: {
                yMin: {
                    label: "y-min",
                    editor: Editors.number()
                },
                yMax: {
                    label: "y-max",
                    editor: Editors.number()
                },
                yAuto: {
                    label: "-",
                    editor: Editors.boolean({
                        label: "auto-scale-y"
                    })
                }
            }
        });

        this.chart.onChanged.add(() => {
            this.control.value = {
                ...this.control.value,
                yMax: this.chart.actualMetrics.y.max,
                yMin: this.chart.actualMetrics.y.min
            };
        });
    }

    /****************************************/

    attach(element: HTMLDivElement) {

        this.chart.attach(element);
    }

    /****************************************/

    readonly chart = new Chart({ xAxis: new TimeChartAxis() });

    readonly control: ObjectEditor<IChartControl>;
}

registerType(ChartView, "ChartView");