﻿import { Http } from "../WebApp";
import { IApiResult } from "./Api";

export class ServerStorage  {

    async setItem(key: string, data: any): Promise<void> {

        const result = await Http.postJsonAsync<IApiResult<boolean>>("/api/object/" + key, data);
        if (!result.result)
            throw new Error(result.error);
    }

    /****************************************/

    async getItem<T>(key: string): Promise<T> {

        const result = await Http.getJsonAsync<IApiResult<T>>("/api/object/" + key);

        return result.result;
    }
}