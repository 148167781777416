﻿import { execAsync, Services } from "./WebApp";

export function logProgress(action: () => Promise<any>, message: string) {

    return execAsync(async op => {

        Services.log.activeOperation = op;

        await action();

        Services.log.activeOperation = undefined;
    },
    {
        message: message,
        showProgress: true
    });
}

export async function sha256(text: string) {

    const resId = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(text));

    return [...new Uint8Array(resId)]
        .map(b => b.toString(16).padStart(2, "0"))
        .join("");
}
