﻿import { SerieViewContent } from "./Content/SerieViewContent";
import { App, ContentActivity, ContentSelector, Drawer, registerType } from "../WebApp";

export class MainActivity extends ContentActivity {

    constructor(activeContent?: string) {

        super({
            providers: [new SerieViewContent()],
            selector: ContentSelector.BottomNav,
            title: App.appName,
            name: "MainActivity",
            activeContent: activeContent,
            menu: new Drawer({
                groups: []
            })
        });
    }

    /****************************************/

    protected async createAsync() {

        await this.createMenuAsync();

        return await super.createAsync();
    }

    /****************************************/

    protected async createMenuAsync() {
       
    }
}

registerType(MainActivity, "MainActivity");