﻿import { ChartAxisType } from "../Abstraction/IChartAxis";
import { IChartDrawer, IChartDrawerOptions } from "../Abstraction/IChartDrawer";
import { ISerieStyle } from "../Abstraction/IChartSerie";
import { IDrawStyle, IPath2D, IRenderEngine } from "../Abstraction/IRenderEngine";

export abstract class BaseChartDrawer<TStyle extends ISerieStyle> implements IChartDrawer {

    draw<TValue extends ChartAxisType>(engine: IRenderEngine, options: IChartDrawerOptions<TValue>) {

        const path = engine.createPath();

        this.createPath(path, options);

        const style: IDrawStyle = {}
        style.fill = options.serie.style?.fillColor;

        if (options.serie.style?.borderColor) {
            style.stroke = options.serie.style?.borderColor;
            style.strokeWidth = options.serie.style?.borderWidth ?? 1;
        }

        engine.drawPath(path, style);
    }

    /****************************************/

    protected abstract createPath<TValue extends ChartAxisType>(path: IPath2D, options: IChartDrawerOptions<TValue>);
}