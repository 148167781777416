﻿import { registerType, isEditable, IEditable, ITypedState   } from "../../WebApp";
import { ITimeSerie } from "../Abstraction/ITimeSerie";
import { ITimeSerieFunction } from "../Abstraction/ITimeSerieFunction";
import { BaseTimeSerieTransform } from "./BaseTimeSerieTransform";

export interface IFunctionSerieTransformState extends ITypedState {
    func: ITypedState;
}

export class FunctionSerieTransform<TFunc extends ITimeSerieFunction> extends BaseTimeSerieTransform<IFunctionSerieTransformState> implements IEditable<FunctionSerieTransform<TFunc>>  {

    constructor(func: TFunc) {
        super();
        this.func = func;
    }

    /****************************************/

    createEditor() {

        if (isEditable(this.func))
            return this.func.createEditor();
    }

    /****************************************/

    async applyAsync(input: ITimeSerie[]) {

        let result: ITimeSerie[] = [];

        for (const serie of input) {
            result.push({
                name: serie.name,
                values: await this.func.applyAsync(serie.values),
                source: serie.source
            })
        }

        return result;
    }

    /****************************************/

    get baseName() {
        return this.func.baseName;

    }
    get name() {
        return this.func.name;
    }

    func: TFunc;
}

registerType(FunctionSerieTransform, "FunctionTransform");