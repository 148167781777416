﻿import { ITimeSerieTransform } from "../../TimeSerie/Abstraction/ITimeSerieTransform";
import { logProgress } from "../../Utils";
import { Editors, BaseItemEditor, Panel, registerType, ItemsSource, Services, ListView, Format, linq, ActionView, OperationType, getTypeName, ISingleItemSelectorConfig  } from "../../WebApp";
import { DimensionFilterEditor } from "./DimensionFilterEditor";
import { BasicSerieProvider } from "../../TimeSerie/Providers/BasicSerieProvider";
import { IItemEditor, IProperties, SingleItemSelector } from "../../WebApp";
import { ISerieDimension, ISerieInfo } from "../../TimeSerie/Abstraction/ITimeSerieInfo";

interface ISerieTransformProvider extends IProperties {

    value: ITimeSerieTransform[];
}

export class BasicSerieEditor extends BaseItemEditor<BasicSerieProvider> {

    protected _transformProvider: ISerieTransformProvider;
    protected _isEditing = 0;
    protected _dimensionList: ListView<ISerieDimension>;
    protected _factorEditor: SingleItemSelector<any, any>;

    constructor(transformProvider: IItemEditor<ITimeSerieTransform[]>) {
        super()

        this.value = new BasicSerieProvider();

        this.view = new Panel({
            name: getTypeName(this),
            styles: ["vertical", "margin-items-v"]
        });

        this._transformProvider = transformProvider;
        this._transformProvider.prop("value").subscribe(() => this.commitAsync());

        this.prop("activeSerie").subscribe(() => this.onActiveSerieChanged());

        this.prop("factor").subscribe(() => this.commitAsync());

         this.view.addView(Editors.singleItemSelector({
            emptyText: "select-serie",
            itemsSource: new ItemsSource({
                getItemsAsync: () => Services.data.listSeriesAsync(),
                getItemText: a => a.name
            }),
            value: this.prop("activeSerie")
        }));

        this._factorEditor = this.view.addView(Editors.singleItemSelector({
            allowNull: true,
            emptyText: "no-factor",    
            name: "factor",
            itemsSource: new ItemsSource<ISerieDimension, string>({
                getItemsAsync: async () => this.activeSerie ? linq(this.activeSerie.dimensions).toArray() : [],
                getItemText: a => Format.text("perc-of", a.name),
                getItemValue: a => a.name
            }),
            value: this.prop("factor")
        }));

        this._dimensionList = this.view.addView(new ListView({
            name: "dimension-list",
            createItemView: a => {
                const editor = new DimensionFilterEditor({
                    dimension: a,
                    onValueChanged: () => this.commitAsync()
                });
                editor.view["@editor"] = editor;
                return editor.view;
            },
            styles: ["scroll-v", "fill-v"],
            itemsSource: new ItemsSource({
                getItemsAsync: async () => this.activeSerie?.dimensions,
            }),
        }));

        this.view.addView(ActionView.fromAction({
            name: "update-source",
            styles: ["primary"],
            operation: OperationType.Global,
            executeAsync: () => logProgress(() => Services.data.updateSerieAsync(this.activeSerie?.name), "Updating...")
        }, { template: "ActionButton" }))
    }

    /****************************************/

    async beginEditAsync(item: BasicSerieProvider): Promise<void> {

        if (!item || this._isEditing)
            return;

        this._isEditing++;

        const info = await Services.data.getSerieInfoAsync(item.serieName);
        this.activeSerie = info;

        await this._factorEditor.refreshAsync();
        this.factor = item.factorName;

        await this._dimensionList.refreshAsync();

        if (item.filters) {
            for (const view of this._dimensionList.content) {
                const editor = view["@editor"] as DimensionFilterEditor;
                const value = item.filters.find(a => a.dimensionName == editor.dimension.name);
                if (value)
                    editor.beginEditAsync(value);
            }
        }

        this._transformProvider.value = item.transforms;

        this.value = item;

        this._isEditing--;
    }

    /****************************************/

    async commitAsync(force?: boolean): Promise<boolean> {

        if (this._isEditing)
            return;

        this._isEditing++;

        if (!this.value)
            this.value = new BasicSerieProvider();

        this.value.filters = [];

        for (const item of this._dimensionList.content) 
            this.value.filters.push(item["@editor"].value);
       
        this.value.factorName = this.factor;
        this.value.serieName = this.activeSerie?.name;
        this.value.transforms = this._transformProvider.value;

        this.prop("value").notifyChanged();

        this._isEditing--;

        return true;
    }

    /****************************************/

    protected async onActiveSerieChanged() {

        if (this._isEditing)
            return;

        this._isEditing++;

        await this._factorEditor.refreshAsync();

        await this._dimensionList.refreshAsync();

        this._isEditing--;

        await this.commitAsync();
    }

    /****************************************/

    activeSerie: ISerieInfo = null;

    factor: string = null;

    value: BasicSerieProvider = null;

    readonly view: Panel;
}

registerType(BasicSerieEditor, "BasicSerieEditor");