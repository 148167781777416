﻿import { BaseChartAxis } from "./BaseChartAxis";
import { IChartAxisConfig } from "../Abstraction/IChartAxis";
import { Services } from "../../../WebApp";

export interface INumberChartAxisConfig extends IChartAxisConfig<number> {
    decimals?: number;
}

export class NumberChartAxis extends BaseChartAxis<number> {

    constructor(config?: INumberChartAxisConfig) {

        super({
            ticks: {
                interval: 1
            },
            label: {
                show: true
            },
            ...config
        });

        this.decimals = config?.decimals ?? 0;
    }

    /****************************************/

    formatValue(value: number): string {

        return value.toLocaleString(Services.application.language, { maximumFractionDigits: this.decimals, minimumFractionDigits: this.decimals });
    }

    /****************************************/

    numberToValue(value: number): number {
        return value;
    }

    /****************************************/

    valueToNumber(value: number): number {
        return value;
    }

    /****************************************/

    decimals: number;
}
