﻿import { Editors, registerType, IEditable, staticItems, Format  } from "../../WebApp";
import { ITimeSerieValue } from "../Abstraction/ITimeSerieValue";
import { TimeUnit } from "../Abstraction/Types";
import { BaseTimeSerieFunction } from "./BaseTimeSerieFunction";

export type MovingAvgPos = "center" | "prev";

export class MovingAvgFuncion extends BaseTimeSerieFunction implements IEditable<MovingAvgFuncion> {

    constructor() {
        super();
        this.delta = 7;
        this.unit = "day";
        this.position = "center";
    }

    /****************************************/

    createEditor() {

        const editor = Editors.object<MovingAvgFuncion>({
            properties: {
                unit: {
                    label: "unit",
                    editor: Editors.singleItemSelector({
                        allowNull: false,
                        itemsSource: staticItems<TimeUnit>([
                            {text: "day", value: "day"}
                        ])
                    })
                },
                delta: {
                    label: "delta",
                    editor: Editors.number({min: 1})
                },
                position: {
                    label: "position",
                    editor: Editors.singleItemSelector({
                        allowNull: false,
                        itemsSource: staticItems<MovingAvgPos>([
                            { text: "center", value: "center" },
                            { text: "prev", value: "prev" }
                        ])
                    })
                }
            }
        });

        editor.beginEditAsync(this);

        return editor;
    }

    /****************************************/

    async applyAsync(serie: ITimeSerieValue[]): Promise<ITimeSerieValue[]> {
        const result: ITimeSerieValue[] = [];

        let deltaA = this.position == "center" ? Math.floor(this.delta / 2) : (this.delta - 1);
        let deltaB = this.position == "center" ? this.delta - deltaA : 1;
        let index = deltaA;
         
        while (index < serie.length) {

            let sum = 0;
            let count = 0;
            for (let i = index - deltaA; i < index + deltaB && i < serie.length; i++) {
                sum += serie[i].value;
                count++;
            }

            sum /= count;

            result.push({
                date: serie[index].date,
                value: sum
            });

            index++;
        }

        return result;
    }

    /****************************************/

    get baseName() {
        return "moving-avg";
    }

    get name() {
        return Format.text("moving-avg-at", this.delta, Format.string(this.unit, { cardinality: this.delta }));
    }

    position: MovingAvgPos;

    delta: number;

    unit: TimeUnit;
}

registerType(MovingAvgFuncion, "MovingAvgFuncion");
