﻿import { getObjectState, ITypedState, setObjectState, IStateManager } from "./WebApp";

export class StateObject<TState extends ITypedState = ITypedState> implements IStateManager {

    constructor(state?: TState) {

        this.initProps();

        if (state)
            this.setState(state);
    }

    /****************************************/

    protected initProps() {

    }

    /****************************************/

    getState(): TState {
         return getObjectState(this, true) as TState;
    }

    /****************************************/

    setState(state: TState) {

        setObjectState(state, this);
    }
}