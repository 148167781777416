﻿import { dateDiff, registerType } from "../../WebApp";
import { ITimeSerieValue } from "../Abstraction/ITimeSerieValue";
import { TimeUnit } from "../Abstraction/Types";
import { BaseTimeSerieFunction } from "./BaseTimeSerieFunction";

export class DeriveFunction extends BaseTimeSerieFunction {

    constructor() {
        super();
        this.delta = 1;
        this.unit = "day";
    }

    /****************************************/

    async applyAsync(serie: ITimeSerieValue[]): Promise<ITimeSerieValue[]> {

        const result: ITimeSerieValue[] = [];

        let lastValue: ITimeSerieValue;

        let index = 0;

        while (index < serie.length) {

            const curValue = serie[index];

            if (lastValue) {

                let diff: number;
                switch (this.unit) {
                    case "day":
                        diff = dateDiff(curValue.date, lastValue.date).totalDays;
                        break;
                }
                if (diff >= this.delta) {
                    result.push({
                        date: curValue.date,
                        value: (curValue.value - lastValue.value) * (this.delta / diff)
                    });
                    lastValue = curValue;
                }
            }
            else
                lastValue = curValue;

            index++;
        }

        return result;
    }

    /****************************************/

    delta: number;

    unit: TimeUnit;

    readonly name = "derive";
}

registerType(DeriveFunction, "DeriveFunction");