﻿import { StateObject } from "../StateObject"
import { ITypedState, INode, INodeFilter, NodeType, IHostInfo, buildGlobalId, getObjectState } from "../WebApp";
import { Project } from "./Project";

export interface IProjectItemState extends ITypedState {

}

export abstract class ProjectItem<TState extends IProjectItemState = IProjectItemState> extends StateObject<TState> implements INode  {

    constructor(state?: TState) {
        super(state);
    }

    async *childNodesAsync(_filter?: INodeFilter): AsyncIterable<INode> {

    }

    getState() {
        return getObjectState(this, true, false, ["host", "globalId", "nodeTypes"]);
    }

    abstract readonly nodeTypes: NodeType[];

    id: string;

    name: string;

    isVisible: boolean;

    host: IHostInfo<Project>;

    get globalId() {
        return buildGlobalId(this, this.id);
    }
}
