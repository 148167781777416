﻿import { IPoint, IRect, ISize } from "../Abstraction/Geometry";
import { IDrawStyle, IPath2D, IRenderEngine, ITextMetrics, ITextStyle } from "../Abstraction/IRenderEngine";
import { wrapText } from "./RenderUtils";
import { SvgPath } from "./SvgPath";


export class SvgRenderEngine implements IRenderEngine {

    protected _metricsCtx: CanvasRenderingContext2D;
    protected _root: SVGElement;
    protected _curGroup: SVGElement;

    constructor() {

        this._metricsCtx = document.createElement("canvas").getContext("2d");
    }

    /****************************************/

    measureText(text: string, style: ITextStyle): ITextMetrics {

        this._metricsCtx.font = style.fontSize + "px " + style.fontFace;

        const result = this._metricsCtx.measureText(text);
        return {
            width: result.width,
            ascent: result.actualBoundingBoxAscent
        };
    }

    /****************************************/

    clipRect(rect: IRect) {

        let defs = this._root.querySelector("defs");

        if (!defs) {
            defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
            this._root.prepend(defs);
        }

        const group = document.createElementNS("http://www.w3.org/2000/svg", "g");

        const clip = document.createElementNS("http://www.w3.org/2000/svg", "clipPath");
        const rectEl = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        rectEl.setAttribute("x", rect.x.toString());
        rectEl.setAttribute("y", rect.y.toString());
        rectEl.setAttribute("width", rect.width.toString());
        rectEl.setAttribute("height", rect.height.toString());
        clip.appendChild(rectEl);
        clip.id = "clip_" + new Date().getTime();

        defs.appendChild(clip);

        group.setAttribute("clip-path", `url(#${clip.id})`);

        this._root.appendChild(group);
        this._curGroup = group;
    }

    /****************************************/

    drawRect(rec: IRect, style: IDrawStyle) {
        throw "Not supported yet";
    }

    /****************************************/

    createPath() {
        return new SvgPath();
    }

    /****************************************/

    drawText(text: string, position: IPoint, maxWidth: number, style: ITextStyle): number {

        const span = (t: string, p: IPoint) => {

            const textEl = document.createElementNS("http://www.w3.org/2000/svg", "text") as SVGTextElement;

            textEl.setAttribute("x", p.x.toString());
            textEl.setAttribute("y", p.y.toString());
            textEl.style.fontFamily = style.fontFace;
            textEl.style.fontSize = style.fontSize + "px";
            textEl.style.fill = style.fill;
            textEl.innerHTML = t;
            this._curGroup.appendChild(textEl)
        }

        if (style.wrap)
            return wrapText(this._metricsCtx, text, position, maxWidth, undefined, style.align ?? "start", (t, p) => span(t, p));

        span(text, position);

        return style.fontSize;
    }

    /****************************************/

    drawPath(path: IPath2D, style: IDrawStyle) {
        const pathEl = document.createElementNS("http://www.w3.org/2000/svg", "path");

        pathEl.setAttribute("d", (path as SvgPath).pathData);
        if (style.fill)
            pathEl.style.fill = style.fill;
        else
            pathEl.style.fill = "none";

        if (style.stroke) {
            pathEl.style.stroke = style.stroke;
            pathEl.style.strokeWidth = (style.strokeWidth ?? 1) + "px";
            if (style.strokePattern)
                pathEl.style.strokeDasharray = style.strokePattern.join(",");
        }
        else
            pathEl.style.stroke = "none";
        this._curGroup.appendChild(pathEl)
    }

    /****************************************/

    createView(): Element {

        const container = document.createElement("div");
        container.className = "svg-container";
        container.style.overflow = "hidden";

        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        container.appendChild(svg);
        return container;
    }

    /****************************************/

    beginDraw(container: HTMLDivElement, background?: string): ISize {

        this._root = container.firstElementChild as SVGSVGElement;
        this._root.innerHTML = "";
        this._root.setAttribute("width", container.clientWidth + "px");
        this._root.setAttribute("height", container.clientHeight + "px");

        if (background)
            this._root.style.fill = background;

        this._curGroup = this._root;

        return {
            width: container.clientWidth,
            height: container.clientHeight
        };
    }

    /****************************************/

    endDraw() {
        this._root = null;
        this._curGroup = null;
    }
}