﻿import { ITimeSerie } from "./ITimeSerie";
import { ITimeSerieSource } from "./ITimeSerieSource";

export enum TimeSerieUnit {
    Day,
    Week,
    Month,
    Year
}

export interface IGroupSerieDimensionValue<TValue = any> extends ISerieDimensionValue {
    values: TValue[];
}

export interface IRangeSerieDimensionValue<TValue = any> extends ISerieDimensionValue {
    from: TValue;
    to: TValue;
}

export interface ISingleSerieDimensionValue<TValue = any> extends ISerieDimensionValue {
    value: TValue;
}

export interface ISerieDimensionValue {
    id: number;
    displayName: string;
}

export interface ISerieDimension<TValue extends ISerieDimensionValue = ISerieDimensionValue> {
    name: string;
    values: TValue[];
}

export interface ISerieInfoSource {
    name: string;
    urls: string[];
}

export interface ISerieInfo {
    name: string;
    tags: string[];
    sources: ISerieInfoSource[];
    lastUpdateTime: string | Date;
    timeUnit: TimeSerieUnit;
    dimensions: ISerieDimension[];
    source: ITimeSerieSource;
}

