﻿import { IPoint, IRect, ISize } from "../Abstraction/Geometry";
import { IDrawStyle, IPath2D, IRenderEngine, ITextMetrics, ITextStyle } from "../Abstraction/IRenderEngine";
import { Path2DInt } from "./Path2DInt";
import { wrapText } from "./RenderUtils";

export class CanvasRenderEngine implements IRenderEngine {
    protected _ctx: CanvasRenderingContext2D;

    createPath() {
        return new Path2DInt();
    }

    /****************************************/

    measureText(text: string, style: ITextStyle) : ITextMetrics {

        this._ctx.font = style.fontSize + "px " + style.fontFace;

        const result = this._ctx.measureText(text);
        return {
            width: result.width,
            ascent: result.actualBoundingBoxAscent
        };
    }

    /****************************************/

    clipRect(rect: IRect) {

        this._ctx.beginPath();
        this._ctx.rect(rect.x, rect.y, rect.width, rect.height);
        this._ctx.clip();
    }

    /****************************************/

    drawText(text: string, position: IPoint, maxWidth: number, style: ITextStyle): number {

        this._ctx.font = style.fontSize + "px " + style.fontFace;

        if (style.fill)
            this._ctx.fillStyle = style.fill;

        if (style.wrap) 
            return wrapText(this._ctx, text, position, maxWidth, undefined, style.align ?? "start", (t, p) => this._ctx.fillText(t, p.x, p.y));

        this._ctx.fillText(text, position.x, position.y);

        return style.fontSize;
    }

    /****************************************/

    drawRect(rec: IRect, style: IDrawStyle) {

        if (style.fill) {
            this._ctx.fillStyle = style.fill;
            this._ctx.fillRect(rec.x, rec.y, rec.width, rec.height);
        }

        if (style.stroke) {
            this._ctx.strokeStyle = style.stroke;
            this._ctx.lineWidth = style.strokeWidth;
            if (style.strokePattern)
                this._ctx.setLineDash(style.strokePattern);
            else
                this._ctx.setLineDash([]);

            this._ctx.strokeRect(rec.x, rec.y, rec.width, rec.height);
        }
    }

    /****************************************/

    drawPath(path: IPath2D, style: IDrawStyle) {

        const curPath = (path as Path2DInt).path;

        if (style.fill) {
            this._ctx.fillStyle = style.fill;
            this._ctx.fill(curPath);
        }

        if (style.stroke) {
            this._ctx.strokeStyle = style.stroke;
            this._ctx.lineWidth = style.strokeWidth;
            if (style.strokePattern)
                this._ctx.setLineDash(style.strokePattern);
            else
                this._ctx.setLineDash([]);

            this._ctx.stroke(curPath);
        }
    }

    /****************************************/

    createView(): HTMLElement {

        return document.createElement("canvas");
    }

    /****************************************/

    beginDraw(container: HTMLCanvasElement, background?: string) {

        let scale = devicePixelRatio;

        let size: ISize;

        if (container.clientWidth && container.clientHeight) {

            container.width = 0;
            container.height = 0;

            size = {
                width: container.clientWidth,
                height: container.clientHeight
            }

            container.width = size.width * scale;
            container.height = size.height * scale;
        }
        else {
            scale = 1;
            size = {
                width: container.width,
                height: container.height
            }
        }

        this._ctx = container.getContext("2d");

        this._ctx.save();

        if (!background)
            this._ctx.clearRect(0, 0, container.width, container.height);
        else {
            this._ctx.fillStyle = background;
            this._ctx.fillRect(0, 0, container.width, container.height);
        }

        this._ctx.translate(0.5, 0.5);
        this._ctx.scale(scale, scale);

        return size;
    }

    /****************************************/

    endDraw() {
        this._ctx.restore();
        this._ctx = null;
    }
}