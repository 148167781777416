﻿
import { StateObject } from "../StateObject";
import { isTimeSerie } from "../TimeSerie/Abstraction/ITimeSerie";
import { ITypedState, observableListOf, Guid, registerType, INode, toArrayAsync, CollectNodeResult, buildGlobalId, IObservableList, INodeFilter, collectNodeValuesAsync, IHostInfo, getObjectState} from "../WebApp";
import { IProjectItemState, ProjectItem } from "./ProjectItem";

export interface IProjectState extends ITypedState {

    activeItem?: ProjectItem | IProjectItemState;

    id?: Guid;

    name?: string;

    items?: ProjectItem[];
}

export interface IProjectViewOptions {
    range?: { min?: Date | string, max?: Date | string }
}

export class Project extends StateObject<IProjectState> implements INode {

    constructor(state?: IProjectState) {
        super(state);
    }

    protected initProps() {
        this.items = observableListOf<ProjectItem>();
        this.items.subscribe({
            onItemAdded: a => a.host = {
                object: this,
                slot: "items"
            },
            onItemRemoved: a => {
                if (a.host?.object == this)
                    a.host = null;
            }
        });
    }

    getState() {
        return getObjectState(this, true, false, ["host", "globalId", "nodeTypes"]);
    }

    async enumTimeSeriesAsync() {
        return await collectNodeValuesAsync<INode>(this, a => {
            if (isTimeSerie(a))
                return CollectNodeResult.Collect;
            return CollectNodeResult.Continue;
        });
    }

    async *childNodesAsync(_filter?: INodeFilter): AsyncIterable<INode> {

        for (const item of this.items)
            yield item;
    }

    readonly nodeTypes = ["Project"];

    id: Guid;

    name: string;

    activeItem: ProjectItem;

    items: IObservableList<ProjectItem>;

    viewOptions?: IProjectViewOptions;

    host: IHostInfo<any>;

    get globalId() {
        return buildGlobalId(this, this.id);
    }

}

registerType(Project, "Project");