﻿import { setObjectState, ITypedState } from "../../WebApp";
import { ITimeSerie } from "./../Abstraction/ITimeSerie";
import { ITimeSerieTransform } from "./../Abstraction/ITimeSerieTransform";
import { BaseTimeSerieTransform } from "./BaseTimeSerieTransform";

interface IConcatSerieTransformState extends ITypedState {

    transforms: ITypedState[];
}

export class ConcatSerieTransform extends BaseTimeSerieTransform<IConcatSerieTransformState> {

    constructor(list: ITimeSerieTransform[]) {
        super();
        if (list)
            this.transforms.push(...list);
    }

    /****************************************/

    async applyAsync(input: ITimeSerie[]) {

        let curInput = input;

        for (const transform of this.transforms) {
            if (transform.isEnabled)
                curInput = await transform.applyAsync(curInput);
        }
        return curInput;
    }

    /****************************************/

    readonly name = "concat";

    transforms: ITimeSerieTransform[] = [];
}