﻿import { Actions, IAppArgs, Services, ViewApplication, NodeManager } from "./WebApp";
import { IntegrateFunction } from "./TimeSerie/Functions/IntegrateFunction";
import { MovingAvgFuncion } from "./TimeSerie/Functions/MovingAvgFuncion";
import { ProjectManager } from "./Services/ProjectManager";
import { LogService } from "./Services/LogService";
import { DeriveFunction } from "./TimeSerie/Functions/DeriveFunction";
import { TimeSplitSerieTransform } from "./TimeSerie/Transforms/TimeSplitSerieTransform";
import { ServerStorage } from "./Services/ServerStorage";
import { RemoteSerieSource } from "./TimeSerie/Sources/RemoteSerieSource";
import { ComputedSerieSource } from "./TimeSerie/Sources/ComputedSerieSource";
import { MultipleSerieSource } from "./TimeSerie/Sources/MultipleSerieSource";
import registerComputed from "./TimeSerie/Computed";

export class DataLabApp extends ViewApplication<IAppArgs> {

    constructor() {
        super();
        this.appName = "DataLab";
    }

    protected async configureServicesAsync() {

        registerComputed();

        await super.configureServicesAsync();

        await Services.log.startAsync();
    }

    /****************************************/

    mainAsync() {

        return Actions.home();
    }
     
    /****************************************/

    protected initServices() {

        super.initServices();


        Services.nodeManager = new NodeManager();
        Services.serverStorage = new ServerStorage();

        Services.timeSerieFunctions = [
            DeriveFunction,
            IntegrateFunction,
            MovingAvgFuncion,
        ];
        Services.timeSerieTransforms = [
            TimeSplitSerieTransform
        ];

        Services.timeSerieSources = [
            RemoteSerieSource,
            ComputedSerieSource
        ]

        Services.data = new MultipleSerieSource();
        Services.projectManager = new ProjectManager();
        Services.log = new LogService();


        this.language = "it-IT";
    }


    get hasMain() { return true; }
}
