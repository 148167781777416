﻿import { parseDate, Services } from "../../WebApp";
import { ITimeSerieValue } from "../Abstraction/ITimeSerieValue";
import { DeriveFunction } from "../Functions/DeriveFunction";
import { registerSerie } from "../Sources/ComputedSerieSource";

const name = "Antigenic Test over Positives (%)";

export default function register() {

    registerSerie({

        async getInfoAsync() {

            var info = await Services.data.getSerieInfoAsync("Italy Covid DPC Total Antigenic Test");
            return {
                ...info,
                name: name
            }
        },

        async getSerieAsync(filter) {

            const derive = new DeriveFunction();

            const serieA = await Services.data.getSerieAsync("Italy Covid DPC Total Positive Antigenic", filter);
            const serieB = await Services.data.getSerieAsync("Italy Covid DPC Total Antigenic Test", filter);

            serieA.values = await derive.applyAsync(serieA.values);
            serieB.values = await derive.applyAsync(serieB.values);

            const values: ITimeSerieValue[] = [];
            for (let i = 0; i < serieA.values.length; i++) {
                values.push({
                    date: parseDate(serieA.values[i].date),
                    value: serieB.values[i].value == 0 ? 0 : serieA.values[i].value / serieB.values[i].value * 100
                });
            }

            return {
                values,
                name: name,
                source: null
            }
        }
    })
}