﻿
import { DynamicString, StringTable, WordGender } from "../WebApp";

StringTable.add({

    "select-all": "[Tutti]",
    "select-serie": "[Seleziona Serie]",
    "update-source": "aggiorna sorgente",
    "sum": "somma",
    "add-serie": "Aggiungi serie",
    "no-factor": "Nessun Fattore",
    "perc-of": DynamicString.complex("% di {0}"),
    "add-item": DynamicString.complex("Aggiungi {0}"),
    "remove-item": DynamicString.complex("Rimuovi {0}"),
    "integrate": "Integrale",
    "derive": "Derivata",
    "form": "dal",
    "to": "al",
    "transform": "trasformazione",
    "moving-avg": "Media mobile",
    "time-split": "Divisione temporale",
    "moving-avg-at": DynamicString.complex("{moving-avg} ({0} {1})"),
    "time-split-at": DynamicString.complex("{time-split} ({0} - {1})"),
    "day": DynamicString.noun({
        singular: { detArticle: "il", name: "giorno" },
        plural: { detArticle: "i", name: "giorni" },
        gender: WordGender.Male
    }),
    "Age": "età",
    "Gender": "Sesso",
    "Male": "Maschi",
    "Female": "Femmine",
    "Clinical State": "Stato Clinico",
    "Asymptomatic": "Asintomatico",
    "Paucisymptomatic": "Puacisintomatico",
    "Light": "Live",
    "Severe": "Severo",
    "Critical": "Critico",
    "Italy": "Italia",
    "Producer": "Produttore",
    "data-lab": "Data Lab",
    "msg-image-copied": "Immagine grafico copiata",
    "msg-serie-copied": "Serie copiata",
    "msg-link-copied": "Link condivisione copiato",
    "select-item": DynamicString.complex("Ricerca")
}, { language: "it-IT" });