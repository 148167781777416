﻿import { IDictionary, linq, Services } from "../../WebApp";
import { ITimeSerie } from "../Abstraction/ITimeSerie";
import { ITimeSerieFilter } from "../Abstraction/ITimeSerieFilter";
import { ISerieInfo } from "../Abstraction/ITimeSerieInfo";
import { ITimeSerieSource } from "../Abstraction/ITimeSerieSource";
import { MultipleSerieSource } from "./MultipleSerieSource";

export interface IComputedTimeSerie {

    getInfoAsync(): Promise<ISerieInfo>;

    getSerieAsync(filter: ITimeSerieFilter): Promise<ITimeSerie>;
}

export class ComputedSerieSource implements ITimeSerieSource {

    protected _series: IComputedTimeSerie[];
    protected _infos: IDictionary<ISerieInfo>;
    protected _map: IDictionary<IComputedTimeSerie>;

    constructor() {

        this._series = [];
    }

    async listSeriesAsync(): Promise<ISerieInfo[]> {

        if (!this._infos) {

            this._map = {};
            this._infos = {};

            for (const serie of this._series) {
                const info = await serie.getInfoAsync();
                this._infos[info.name] = info;
                info.source = this;
                this._map[info.name] = serie;
            }
        }

        return linq(this._infos).select(a => a.value).toArray();
    }

    async getSerieInfoAsync(name: string): Promise<ISerieInfo> {

        if (!this._infos)
            await this.listSeriesAsync();
        return this._infos[name];
    }

    async getSerieAsync(name: string, filter?: ITimeSerieFilter): Promise<ITimeSerie> {

        return this._map[name].getSerieAsync(filter);
    }

    async updateSerieAsync(name: string): Promise<boolean> {

        return true;
    }

    add(serie: IComputedTimeSerie) {

        this._series.push(serie);
    }

    readonly name: string = "Computed";
}


export function registerSerie(serie: IComputedTimeSerie) {

    return ((Services.data as MultipleSerieSource).sources.find(a => a.name == "Computed") as ComputedSerieSource).add(serie);
}