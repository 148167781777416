﻿import { Editors, registerType, IEditable, ISimpleItem, ItemsSource, Format, formatDate, parseDate } from "../../WebApp";
import { ITimeSerie } from "./../Abstraction/ITimeSerie";
import { BaseTimeSerieTransform } from "./BaseTimeSerieTransform";

export interface ITimePoint {
    day: number;
    month: number;
}

/****************************************/

export interface ITimeRange {
    from: ITimePoint;
    to: ITimePoint;
}

/****************************************/

function getTimePoint(value: ITimePoint | Date) {

    if (value instanceof Date)
        value = {
            day: value.getDate(),
            month: value.getMonth() + 1
        };

    return value;
}

/****************************************/

function compareDay(a: ITimePoint | Date, b: ITimePoint | Date) : number {

    a = getTimePoint(a);
    b = getTimePoint(b);

    if (a.month == b.month) {
        if (a.day == b.day)
            return 0;
        return a.day < b.day ? -1 : 1;
    }

    if (a.month > b.month)
        return 1;

    return -1;
}

/****************************************/

function range<T>(from, to, action: (v: number) => T): T[] {

    const result: T[] = [];
    for (let i = from; i <= to; i++)
        result.push(action(i));
    return result;
}

/****************************************/

function getMonthNames() {

    return range<ISimpleItem<number>>(1, 12, a => ({
        value: a,
        text: formatDate(new Date(0, a, 0), "{MMMM}")
    }));
}

/****************************************/

function formatTimePoint(value: ITimePoint) {
    return formatDate(new Date(0, value.month - 1, value.day), "{DD} {MMM}");
}

/****************************************/

function createTimePointEditor() {
    return Editors.object<ITimePoint>({
        styles: ["horizontal"],
        properties: {
            day: {
                editor: Editors.singleItemSelector<number, number>({
                    allowNull: false,
                    itemsSource: new ItemsSource({
                        getItemsAsync: async a => range(1, 31, a => a),
                    })
                })
            },
            month: {
                editor: Editors.singleItemSelector<ISimpleItem<number>, number>({
                    allowNull: false,
                    itemsSource: new ItemsSource({
                        getItemsAsync: async a => getMonthNames(),
                        getItemValue: a => a.value,
                        getItemText: a => Format.text(a.text)
                    })
                })
            }
        }
    })
}

/****************************************/

function createRangeEditor() {

    return Editors.object<ITimeRange>({
        properties: {
            from: {
                label: "from",
                editor: createTimePointEditor()
            },
            to: {
                label: "to",
                editor: createTimePointEditor()
            }
        }
    });
}

/****************************************/

export class TimeSplitSerieTransform extends BaseTimeSerieTransform implements IEditable<TimeSplitSerieTransform> {

    constructor() {
        super();
        this.range = {
            from: { month: 8, day: 1 },
            to: { month: 4, day: 1 }
        }
    }

    createEditor() {

        const editor = Editors.object<TimeSplitSerieTransform>({
            properties: {
                range: {
                    editor: createRangeEditor()
                }
            }
        });

        editor.beginEditAsync(this);
        return editor;
    }

    async applyAsync(input: ITimeSerie[]) {

        let result: ITimeSerie[] = [];

        for (const serie of input) {
            let curSerie: ITimeSerie;

            const dir = compareDay(this.range.from, this.range.to);

            for (const value of serie.values) {

                const date = parseDate(value.date);

                const inRange = (dir < 0 && compareDay(date, this.range.from) >= 0 && compareDay(date, this.range.to) <= 0) ||
                    (dir > 0 && (compareDay(date, this.range.to) <= 0 || compareDay(date, this.range.from) >= 0));

                const isEndRange = compareDay(date, this.range.to) == 0;

                const yearOfs = (dir > 0 && compareDay(date, this.range.to) <= 0 ? 1 : 0);

                if (!curSerie && inRange) {

                    let year = date.getFullYear() - yearOfs;

                    curSerie = {
                        name: year.toString() + (dir > 0 ? "-" + (year + 1).toString().substring(2) : ""),
                        values: [],
                        source: serie.source
                    }
                    result.push(curSerie);
                }

                if (curSerie) {
                    if (inRange) {
                        const newDate = new Date(0, date.getMonth(), date.getDate());
                        newDate.setFullYear(yearOfs);
                        curSerie.values.push({
                            date: newDate,
                            value: value.value
                        });
                    }

                    if (isEndRange)
                        curSerie = null;
                }
            }
        }

        return result;
    }

    /****************************************/

    get baseName() {
        return "time-split";
    }

    get name() {
        return Format.title("time-split-at", formatTimePoint(this.range.from), formatTimePoint(this.range.to));
    }

    range: ITimeRange;
}

registerType(TimeSplitSerieTransform, "TimeSplitSerieTransform");
