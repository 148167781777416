﻿
import { DynamicString, StringTable, WordGender } from "../WebApp";

StringTable.add({

    "select-all": "[All]",
    "select-serie": "[Select serie]",
    "update-source": "Update source",
    "sum": "sum",
    "add-serie": "Add serie",
    "no-factor": "No factor",
    "perc-of": DynamicString.complex("% of {0}"),
    "add-item": DynamicString.complex("add {0}"),
    "remove-item": DynamicString.complex("remove {0}"),
    "integrate": "integrate",
    "derive": "derive",
    "moving-avg": "moving average",
    "time-split": "time split",
    "moving-avg-at": DynamicString.complex("{moving-avg} ({0} {1})"),
    "time-split-at": DynamicString.complex("{time-split} ({0} - {1})"),
    "day": DynamicString.noun({
        singular: { detArticle: "a", name: "day" },
        plural: { detArticle: "some", name: "days" },
        gender: WordGender.Male
    }),
    "data-lab": "Data Lab"

}, { language: "en-US" });