﻿import { Actions, IPage } from "./WebApp";
import { MainActivity } from "./View/MainActivity";

declare global {

    export interface IActions {

        home(): Promise<IPage>;
    }
}

/****************************************/

Actions.home = () => {

    return Actions.loadPageAsync(new MainActivity());
}
