﻿import { StateObject } from "../../StateObject";
import { LocalString, ITypedState } from "../../WebApp";
import { ITimeSerieFunction } from "../Abstraction/ITimeSerieFunction";
import { ITimeSerieValue } from "../Abstraction/ITimeSerieValue";

export abstract class BaseTimeSerieFunction<TState extends ITypedState = ITypedState> extends StateObject<TState> implements ITimeSerieFunction {

    abstract applyAsync(serie: ITimeSerieValue[]): Promise<ITimeSerieValue[]>;

    get baseName() { return this.name; }

    abstract readonly name: LocalString;
}