﻿import { IPoint } from "../Abstraction/Geometry";
import { Align } from "../Abstraction/IRenderEngine";

export function wrapText(ctx: CanvasRenderingContext2D, text: string, pos: IPoint, maxWidth: number, lineHeight: number, align: Align, draw: (text: string, pos: IPoint) => void) {

    if (maxWidth <= 0)
        return;

    let words = text.split(" ");
    let line = "";
    let width: number;
    let y = pos.y; 

    for (let n = 0; n <= words.length; n++) {
        const testLine = line + words[n] + " ";
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;
        if (n == words.length || ( testWidth > maxWidth && n > 0)) {
            let x;
            switch (align) {
                case "start":
                    x = pos.x;
                    break;
                case "center":
                    x = pos.x + (maxWidth - width) / 2;
                    break;
                case "end":
                    x = pos.x + maxWidth - width;
                    break;
            }
            draw(line, { x:x, y: y });
            line = "";
            width = 0;
            y += lineHeight ?? metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
            if (n != words.length)
                n--;
        }
        else {
            line = testLine;
            width = testWidth
        }
    }

    return y - pos.y;
}