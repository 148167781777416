﻿import { ChartAxisType } from "../Abstraction/IChartAxis";
import { IChartDrawerOptions } from "../Abstraction/IChartDrawer";
import { ISerieStyle } from "../Abstraction/IChartSerie";
import { IPath2D, IRenderEngine } from "../Abstraction/IRenderEngine";
import { BaseChartDrawer } from "./BaseChartDrawer";

declare global {

    interface ChartTypeMap {
        "lines": ILineChartStyle
    }
}

export interface ILineChartStyle extends ISerieStyle {

    showPoints?: boolean;

    pointSize?: number;

    pointColor?: string;
}

/****************************************/

export class LineChartDrawer extends BaseChartDrawer<ILineChartStyle> {

    protected createPath<TValue extends ChartAxisType>(path: IPath2D, options: IChartDrawerOptions<TValue>) {

        if (options.serie.values?.length > 1) {
            const startPoint = options.viewTransform(options.serie.values[0]);
            path.moveTo(startPoint.x, startPoint.y);

            for (let i = 1; i < options.serie.values.length; i++) {
                const point = options.viewTransform(options.serie.values[i]);
                path.lineTo(point.x, point.y);
            }
        }
    }

    /****************************************/

    protected createPointsPath<TValue extends ChartAxisType>(path: IPath2D, options: IChartDrawerOptions<TValue>, radius: number) {

        if (options.serie.values?.length > 1) {
            for (let i = 0; i < options.serie.values.length; i++) {
                const point = options.viewTransform(options.serie.values[i]);
                path.circle(point.x, point.y, radius);
            }
        }
    }

    /****************************************/

    draw<TValue extends ChartAxisType>(engine: IRenderEngine, options: IChartDrawerOptions<TValue>) {

        const curStyle: ILineChartStyle = {
            showPoints: true,
            pointSize: 3,
            pointColor: options.serie.style?.borderColor,
            ...options.serie.style
        }

        const linePath = engine.createPath();
        this.createPath(linePath, options);

        engine.drawPath(linePath, {
            stroke: curStyle.borderColor,
            strokeWidth: curStyle.borderWidth
        });

        if (curStyle.showPoints) {

            if (options.scale.x > curStyle.pointSize * 3) {
                const pointPath = engine.createPath();
                this.createPointsPath(pointPath, options, curStyle.pointSize);

                engine.drawPath(pointPath, {
                    fill: curStyle.pointColor
                });
            }
        }
    }
}