﻿import { IMinMax } from "../Abstraction/Geometry";
import { ChartAxisType, IChartAxis, IChartAxisConfig, IChartAxisGrid, IChartAxisLabel, IChartAxisTicks, IChartLineStyle, IChartTextStyle } from "../Abstraction/IChartAxis";

export abstract class BaseChartAxis<TValue extends ChartAxisType> implements IChartAxis<TValue> {

    constructor(config?: IChartAxisConfig<TValue>) {

        this.label = {
            show: true,
            ...config?.label,
            style: {
                color: "#000",
                fontFace: "Arial",
                fontSize: 14,
                orientation: 0,
                ...config?.label?.style
            }
        }

        this.ticks = {
            show: true,
            interval: "auto",
            ...config?.ticks,
            style: {
                color: "#000",
                width: 1,
                pattern: [],
                ...config?.ticks?.style
            }
        }

        this.grid = {
            show: true,
            ...config?.grid,
            style: {
                color: "#999",
                width: 1,
                pattern: [2, 2],
                ...config?.grid?.style
            }
        }

        this.showAxis = config?.showAxis === undefined ? true : config?.showAxis;
        this.min = config.min;
        this.max = config.max;
        this.interval = config?.interval ?? 1;
        this.rangeMode = config.rangeMode ?? "window";
    }

    /****************************************/

    nearestNumber(value: number): number {

        if (isNaN(value))
            return null;

        return Math.round(value / this.interval) * this.interval;
    } 

    /****************************************/

    formatValue(value: TValue): string {

        if (value === null || value === undefined)
            return "";

        return value.toString();
    }

    /****************************************/

    abstract valueToNumber(value: TValue): number;

    abstract numberToValue(value: number): TValue;

    get range() : IMinMax {
        return {
            min: this.min !== null ? this.valueToNumber(this.min) : null,
            max: this.max !== null ? this.valueToNumber(this.max) : null,
        }
    }

    ticks: IChartAxisTicks;

    label: IChartAxisLabel;

    grid: IChartAxisGrid;

    showAxis: boolean;

    rangeMode: "all" | "window" | "manual";

    scale: "all" | "window";

    interval: number;

    min?: TValue;

    max?: TValue;
}
