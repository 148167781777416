﻿import { ITimeSerieSource } from "./ITimeSerieSource";
import { ITimeSerieValue } from "./ITimeSerieValue";

export interface ITimeSerie {

    name: string;

    values: ITimeSerieValue[];

    source: ITimeSerieSource;
}


export function isTimeSerie(obj: any): obj is ITimeSerie {

    return obj && typeof obj == "object" && Array.isArray(obj["values"]) && typeof obj["name"] == "string";
}