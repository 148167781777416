﻿import { event, linq, observableListOf, renderView, isTouchDevice, ViewComponent, IHTMLAttach, registerType } from "../../WebApp";
import { ChartAxisType, IChartAxis } from "./Abstraction/IChartAxis";
import { IChartMetrics, IChartSerie, ISerieValue } from "./Abstraction/IChartSerie";
import { IMinMax, IPoint, IRect, ISize } from "./Abstraction/Geometry";
import { NumberChartAxis } from "./Axis/NumberChartAxis";
import { LineChartDrawer } from "./Drawer/LineChartDrawer";
import { IChartDrawer, IChartDrawerOptions } from "./Abstraction/IChartDrawer";
import { ChartRenderTarget, IChart, IChartImageOptions, IChartLegend, IChartRenderOptions, IChartTableOptions, IChartTitle } from "./Abstraction/IChart";
import { IRenderEngine, ITextStyle } from "./Abstraction/IRenderEngine";
import { CanvasRenderEngine } from "./Render/CanvasRenderEngine";
import { SvgRenderEngine } from "./Render/SvgRenderEngine";
import "AppTemplates/Legend.html"

const labelAxisMargin = 8;
const maxTicks = 10;
const minLabelMargin = 16;
const tickSize = 6;

/****************************************/

export interface ICharSerieValueView {

    name: string;
    color: string;
    value: string;
}

/****************************************/

function getDecimals(n: number){

    const value = n.toString();
    const sep = value.lastIndexOf(".");
    if (sep == -1)
        return 0;
    return value.length - (sep + 1);
}

/****************************************/

export class ChartValueInfoView extends ViewComponent implements IHTMLAttach {

    constructor() {
        super({template: "Legend"});
        this.prop("xValue");
        this.prop("top");
        this.prop("left");
        this.prop("height");
    }

    /****************************************/

    attach(element: HTMLElement) {
        this.element = element;
    }

    /****************************************/

    xValue: string = null;

    left: number = 0;

    top: number = 0;

    height: number = 0;

    readonly series = observableListOf<ICharSerieValueView>();
}

registerType(ChartValueInfoView, "ChartValueInfoView");

/****************************************/

export interface IChartOptions<TX extends ChartAxisType> {

    engine?: IRenderEngine;

    container?: HTMLElement | string;

    xAxis: IChartAxis<TX>;
}

/****************************************/

export class Chart<TX extends ChartAxisType> implements IChart<TX> {

    protected _zoom: HTMLElement;
    protected _container: HTMLElement;
    protected _currentValues: ChartValueInfoView;
    protected _lastViewOptions: IChartRenderOptions;
    protected _updateCount = 0;
    protected _mainView: HTMLElement;
    protected _zoomView: Element;
    protected _legend: IChartLegend;
    protected _title: IChartTitle;


    protected _globalMetrics: IChartMetrics = {
        x: {} as IMinMax,
        y: {} as IMinMax,
        yw: {} as IMinMax,
    };

    protected _actualMetrics: IChartMetrics = {
        x: {} as IMinMax,
        y: {} as IMinMax
    };

    constructor(options: IChartOptions<TX>) {

        this._legend = {} as any;

        this._title = {
            show: true,
            style: {
                fontFace: "Arial",
                color: "#000",
                fontSize: 18,
                align: "center"
            },
            position: "top",
            text: ""
        };

        this._legend = {
            show: true,
            textStyle: {
                fontFace: "Arial",
                color: "#000",
                fontSize: 16,
                align: "center"
            },
            position: "bottom",
            orientation: "horizontal"
        };

        this._currentValues = new ChartValueInfoView();

        this.engine = options?.engine ?? new CanvasRenderEngine();

        if (options?.container)
            this.attach(options.container);

        this.series.subscribe({
            onChanged: () => {
                this.updateAsync();
            }
        });

        this.xAxis = options.xAxis;
        this.yAxis = new NumberChartAxis();
        this.yAxis.rangeMode = "window";
        this.padding = 16;

        this.drawers["lines"] = new LineChartDrawer();
    }

    /****************************************/

    async getImageAsync(options: IChartImageOptions): Promise<File> {

        let engine: IRenderEngine;

        if (options.format == "svg")
            engine = new SvgRenderEngine();
        else 
            engine = new CanvasRenderEngine();

        const view = engine.createView();

        let renderSize: ISize = {
            width: options.width ?? this._container.clientWidth,
            height: options.height ?? this._container.clientHeight
        }

        const viewRect = {
            x: options.padding,
            y: options.padding, 
            width: (options.width ?? this._container.clientWidth) - options.padding * 2,
            height: (options.height ?? this._container.clientHeight) - options.padding * 2,
        };

        if (options.format == "png") {
            (view as HTMLCanvasElement).width = renderSize.width;
            (view as HTMLCanvasElement).height = renderSize.height;
        }

        engine.beginDraw(view, options.background);

        this.draw(engine, {
            viewRect,
            metrics: this._actualMetrics,
            target: "image"
        });

        engine.endDraw();

        if (options.format == "svg") {

            const svg = view.firstChild as SVGSVGElement;
            svg.width.baseVal.value = renderSize.width;
            svg.height.baseVal.value = renderSize.height;

            const content = "<svg xmlns=\"http://www.w3.org/2000/svg\" " + view.innerHTML.substring(4)
            return new File([content], "chart.svg", { type: "image/svg+xml" });
        }

        const blob = await new Promise<Blob>(res => (view as HTMLCanvasElement).toBlob(blob => res(blob)));

        return new File([blob], "chart.png", { type: "image/png" });
    }

    /****************************************/

    getTable(options?: IChartTableOptions) {

        const curI = [];
        const table: string[][] = [];

        const head = ["Date"];

        for (let i = 0; i < this.series.count; i++) {
            curI.push(0);
            head.push(this.series.get(i).name);
        }

        table.push(head);

        if (!options?.fullX) {

            while (true) {

                const row = [];

                const curX = linq(this.series).select((a, i) => curI[i] >= a.values.length ? Number.POSITIVE_INFINITY : this.xAxis.valueToNumber(a.values[curI[i]].x)).min();

                row[0] = this.xAxis.formatValue(this.xAxis.numberToValue(curX));

                let hasValue = false;
                for (let i = 0; i < this.series.count; i++) {
                    const serie = this.series.get(i);
                    if (curI[i] < serie.values.length) {
                        hasValue = true;
                        if (this.xAxis.valueToNumber(serie.values[curI[i]].x) == curX) {
                            row[i + 1] = this.yAxis.formatValue(serie.values[curI[i]].y);
                            curI[i]++;
                        }
                    }

                }

                if (!hasValue || curX > this.metrics.x.max)
                    break;

                if (row.length > 1 && curX >= this.metrics.x.min)
                    table.push(row);
            }
        }
        else {

            let curX = this.metrics.x.min;

            while (curX <= this.metrics.x.max) {
                const row = [];
                row[0] = this.xAxis.formatValue(this.xAxis.numberToValue(curX));

                for (let i = 0; i < this.series.count; i++) {
                    const serie = this.series.get(i);

                    let serieX: number;
                    while (curI[i] < serie.values.length && (serieX = this.xAxis.valueToNumber(serie.values[curI[i]].x)) < curX)
                        curI[i]++;

                    if (serieX == curX)
                        row[i + 1] = this.yAxis.formatValue(serie.values[curI[i]].y);
                }
                table.push(row);
                curX++;
            }
        }

        return table;
    }

    /****************************************/

    attach(container: HTMLElement | string) {

        if (typeof (container) == "string")
            container = document.querySelector(container) as HTMLElement;

        this._container = container;

        const legendView = document.createElement("div");
        this._container.appendChild(legendView);

        renderView(container, this._currentValues);

        this._mainView = this.engine.createView() as HTMLElement;
        this._mainView.className = "main-view";
        this._container.appendChild(this._mainView);

        if (isTouchDevice()) { 
            
            this._mainView.addEventListener("touchmove", ev => {
                const rect = this._mainView.getBoundingClientRect();
                this.showCurrentValues({ x: ev.touches[0].clientX - rect.x, y: ev.touches[0].clientY - rect.y });
            }, { passive: true });
            this._mainView.addEventListener("touchend", ev => this.hideCurrentValues());
        }
        else {
            this._mainView.addEventListener("pointermove", ev => this.showCurrentValues({ x: ev.offsetX, y: ev.offsetY }));
            this._mainView.addEventListener("pointerleave", ev => this.hideCurrentValues());
        }

        this._zoom = document.createElement("div");
        this._zoom.className = "chart-zoom";

        const slice = document.createElement("div");
        slice.className = "slice";
        this.attachSlice("all", slice);

        const left = document.createElement("div");
        left.className = "left";
        this.attachSlice("left", left);

        const right = document.createElement("div");
        right.className = "right";
        this.attachSlice("right", right);

        slice.appendChild(left);
        slice.appendChild(right);

        this._zoomView = this.engine.createView();
        this._zoomView.className = "zoom-view";
        this._zoom.appendChild(this._zoomView);
        this._zoom.appendChild(slice);

        container.appendChild(this._zoom);

        this.updateAsync();

        const resizeObserver = new ResizeObserver(([entry]) => {
            setTimeout(() => this.updateAsync());
        });
        resizeObserver.observe(container);
    }

    /****************************************/

    showCurrentValues(viewPoint: IPoint) {

        if (!linq(this.series).any(a => a.isVisible) || !this._lastViewOptions)
            return;

        if (viewPoint.y < this._lastViewOptions.viewRect.y  ||
            viewPoint.y > (this._lastViewOptions.viewRect.y + this._lastViewOptions.viewRect.height)) {

            this.hideCurrentValues();
            return;
        }

        viewPoint.x = Math.min(Math.max(viewPoint.x, this._lastViewOptions.viewRect.x), this._lastViewOptions.viewRect.x + this._lastViewOptions.viewRect.width);

        var seriePoint = this.getSeriePoint(viewPoint, this._lastViewOptions);

        this._currentValues.xValue = this.xAxis.formatValue(seriePoint.x);
        this._currentValues.series.clear();

        for (const serie of this.series) {

            const serieValue = this.getSerieValueAtX(serie, seriePoint.x);

            if (serieValue) {
                this._currentValues.series.add({
                    name: serie.name,
                    value: this.yAxis.formatValue(serieValue),
                    color: serie.style.borderColor ?? serie.style.fillColor
                });
            }
        }


        this._currentValues.top = this._mainView.offsetTop + this._lastViewOptions.viewRect.y;
        this._currentValues.height = this._lastViewOptions.viewRect.height;
        this._currentValues.left = this._mainView.offsetLeft + this.getViewPoint(seriePoint, this._lastViewOptions).x;


        if (this._currentValues.element) {
            const valuesElement = this._currentValues.element.querySelector(".values") as HTMLDivElement;
            if (this._currentValues.element.offsetLeft + valuesElement.clientWidth > this._lastViewOptions.viewRect.width + this._lastViewOptions.viewRect.x)
                this._currentValues.addStyle("left");
            else
                this._currentValues.removeStyle("left");
        }

        this._currentValues.visible = true;
    }

    /****************************************/

    hideCurrentValues() {
        this._currentValues.visible = false;
    }

    /****************************************/

    protected attachSlice(part: string, element: HTMLDivElement) {

        let isMoving = false;
        let startX: number;
        let startV: number;

        const onStart = (ev: PointerEvent | TouchEvent) => {

            if (isMoving)
                return;

            isMoving = true;

            if (part == "right")
                startV = (this.xAxis.range.max ?? this._globalMetrics.x.max);
            else
                startV = (this.xAxis.range.min ?? this._globalMetrics.x.min);

            if ("pointerId" in ev) {
                startX = ev.clientX;
                element.setPointerCapture(ev.pointerId);
            }
            else {
                startX = ev.touches[0].clientX;
            }

            ev.stopPropagation();
            ev.preventDefault();
            ev.stopImmediatePropagation();
        }

        const onMove = (ev: PointerEvent | TouchEvent) => {

            if (!isMoving)
                return;

            const scale = this._zoomView.clientWidth / (this._globalMetrics.x.max - this._globalMetrics.x.min);

            let curX: number;

            if ("pointerId" in ev) {
                curX = ev.clientX;
                element.setPointerCapture(ev.pointerId);
            }
            else {
                curX = ev.touches[0].clientX;
            }

            const deltaX = curX - startX;
            const deltaV = deltaX / scale;

            this.onSliceMove(part, startV, deltaV);
        }

        const onEnd = (ev: PointerEvent | TouchEvent) => {

            isMoving = false;

            if ("pointerId" in ev) 
                element.releasePointerCapture(ev.pointerId);

            ev.stopPropagation();
            ev.preventDefault();
            ev.stopImmediatePropagation();
        }

        element.addEventListener("pointerdown", ev => onStart(ev));
        element.addEventListener("pointermove", ev => onMove(ev));
        element.addEventListener("pointerup", ev => onEnd(ev));
        /*
        element.addEventListener("touchstart", ev => onStart(ev));
        element.addEventListener("touchmove", ev => onMove(ev));
        element.addEventListener("touchend", ev => onEnd(ev));*/
    }

    /****************************************/

    protected onSliceMove(part: string, start: number, delta: number) {

        const curRange = { ...this.xAxis.range }

        if (part == "left") {
            let curPos = start + delta;
            curPos = Math.min((curRange.max ?? this._globalMetrics.x.max) - 1, Math.max(this._globalMetrics.x.min, curPos));
            if (curPos == this._globalMetrics.x.min)
                curRange.min = null;
            else
                curRange.min = curPos;

        }
        else if (part == "right") {
            let curPos = start + delta;
            curPos = Math.max((curRange.min ?? this._globalMetrics.x.min) + 1, Math.min(this._globalMetrics.x.max, curPos));
            if (curPos == this._globalMetrics.x.max)
                curRange.max = null;
            else
                curRange.max = curPos;
        }
        else if (part == "all") {
            let curPos = start + delta;

            var size = (curRange.max ?? this._globalMetrics.x.max) - (curRange.min ?? this._globalMetrics.x.min);

            curPos = Math.max(this._globalMetrics.x.min, curPos);
            if (curPos + size > this._globalMetrics.x.max)
                curPos = this._globalMetrics.x.max - size;

            if (curPos == this._globalMetrics.x.min)
                curRange.min = null;
            else
                curRange.min = curPos;

            if (curPos + size == this._globalMetrics.x.max)
                curRange.max = null;
            else
                curRange.max = curPos + size;
        }

        this.xAxis.min = curRange.min ? this.xAxis.numberToValue(this.xAxis.nearestNumber(curRange.min)) : null;
        this.xAxis.max = curRange.max ? this.xAxis.numberToValue(this.xAxis.nearestNumber(curRange.max)) : null;

        this.updateMetrics();
        this.updateZoomView();
        this.renderViewAsync();
        this.onZoomChanged.raise(this);
    }

    /****************************************/

    async updateAsync() {

        if (this._updateCount > 0)
            return;

        this.updateMetrics();
        await this.renderViewAsync();
        await this.renderZoomViewAsync();


    }
     
    /****************************************/

    protected updateZoomView() {
        if (!this._zoom)
            return;
        const min = this.xAxis.range.min ?? this._globalMetrics.x.min;
        const max = this.xAxis.range.max ?? this._globalMetrics.x.max;
        const scaleX = this._zoomView.clientWidth / (this._globalMetrics.x.max - this._globalMetrics.x.min);
        const slide = this._zoom.querySelector(".slice") as HTMLDivElement;
        slide.style.left = ((min - this._globalMetrics.x.min) * scaleX) + "px";
        slide.style.width = ((max - min) * scaleX) + "px";
    }

    /****************************************/

    protected async renderAsync(view: Element, target?: ChartRenderTarget, padding?: IPoint) {

        return new Promise<void>(res => window.requestAnimationFrame(() => {

            if (!view || view.clientWidth <= 0 || view.clientHeight <= 0)
                return;

            if (!linq(this.series).any(a => a.isVisible))
                return;

            const viewSize = this.engine.beginDraw(view);

            const viewRect: IRect = {
                x: 0 + padding?.x ?? 0,
                y: 0 + padding?.y ?? 0,
                width: viewSize.width - ((padding?.x ?? 0) * 2),
                height: viewSize.height - ((padding?.y ?? 0) * 2),
            };

            const options: IChartRenderOptions = {
                viewRect: viewRect,
                metrics: target == "zoom" ? this._globalMetrics : this._actualMetrics,
                target
            }

            this.draw(this.engine, options);

            this.engine.endDraw();

            if (options.target == "chart")
                this._lastViewOptions = options;

            res();
        }));
    }

    /****************************************/

    protected async renderViewAsync() {

        await this.renderAsync(this._mainView, "chart", { x: this.padding, y: this.padding }); 
    }

    /****************************************/

    protected async renderZoomViewAsync() {

        await this.renderAsync(this._zoomView, "zoom", { y: 4, x: 0 }); 
        this.updateZoomView();
    }

    /****************************************/

    protected updateMetrics() {

        this._globalMetrics = {
            x: {
                min: Number.POSITIVE_INFINITY,
                max: Number.NEGATIVE_INFINITY,
                decimals: 0
            },
            y: {
                min: Number.POSITIVE_INFINITY,
                max: Number.NEGATIVE_INFINITY,
                decimals: 0
            },
            yw: {
                min: Number.POSITIVE_INFINITY,
                max: Number.NEGATIVE_INFINITY,
                decimals: 0
            }
        };

        const xRange = this.xAxis.range;

        for (const serie of this.series) {

            if (!serie.isVisible)
                continue;

            serie.metrics = {
                x: {
                    min: Number.POSITIVE_INFINITY,
                    max: Number.NEGATIVE_INFINITY,
                    decimals: 0
                },
                y: {
                    min: Number.POSITIVE_INFINITY,
                    max: Number.NEGATIVE_INFINITY,
                    decimals: 0
                },
                yw: {
                    min: Number.POSITIVE_INFINITY,
                    max: Number.NEGATIVE_INFINITY,
                    decimals: 0
                }
            };

            for (const value of serie.values) {
                const xValue = this.xAxis.valueToNumber(value.x);
                const yValue = this.yAxis.valueToNumber(value.y);

                serie.metrics.x.min = Math.min(serie.metrics.x.min, xValue);
                serie.metrics.x.max = Math.max(serie.metrics.x.max, xValue);
                serie.metrics.x.decimals = Math.max(serie.metrics.x.decimals, getDecimals(xValue));

                serie.metrics.y.min = Math.min(serie.metrics.y.min, yValue);
                serie.metrics.y.max = Math.max(serie.metrics.y.max, yValue);
                serie.metrics.y.decimals = Math.max(serie.metrics.y.decimals, getDecimals(yValue));

                if ((!xRange.min || xValue >= xRange.min) &&
                    (!xRange.max || xValue <= xRange.max)) {

                    serie.metrics.yw.min = Math.min(serie.metrics.yw.min, yValue);
                    serie.metrics.yw.max = Math.max(serie.metrics.yw.max, yValue);
                }
            }

            this._globalMetrics.x.min = Math.min(this._globalMetrics.x.min, serie.metrics.x.min);
            this._globalMetrics.x.max = Math.max(this._globalMetrics.x.max, serie.metrics.x.max);
            this._globalMetrics.x.decimals = Math.max(this._globalMetrics.x.decimals, serie.metrics.x.decimals);

            this._globalMetrics.y.min = Math.min(this._globalMetrics.y.min, serie.metrics.y.min);
            this._globalMetrics.y.max = Math.max(this._globalMetrics.y.max, serie.metrics.y.max);
            this._globalMetrics.y.decimals = Math.max(this._globalMetrics.y.decimals, serie.metrics.y.decimals);

            this._globalMetrics.yw.min = Math.min(this._globalMetrics.yw.min, serie.metrics.yw.min);
            this._globalMetrics.yw.max = Math.max(this._globalMetrics.yw.max, serie.metrics.yw.max);
            this._globalMetrics.yw.decimals = Math.max(this._globalMetrics.yw.decimals, serie.metrics.yw.decimals);
        }

        this.updateActualMetrics();

        this.onChanged.raise(this);
    }

    /****************************************/

    beginUpdate() {
        this._updateCount++;
    }

    /****************************************/

    endUpdate() {
        this._updateCount--;
        if (this._updateCount == 0)
            this.updateAsync();
    }

    /****************************************/

    adjustAxisRanges() {

        const xRange = this.xAxis.range;

        if (xRange.min != null && (isNaN(this._actualMetrics.x.min) || xRange.min < this._actualMetrics.x.min))
            this.xAxis.min = null;
        if (xRange.max != null && (isNaN(this._actualMetrics.x.max) || xRange.max > this._actualMetrics.x.max))
            this.xAxis.max = null;
    }

    /****************************************/

    getSerieValueAtX(serie: IChartSerie<TX, any>, x: TX) : number {

        const xNum = this.xAxis.valueToNumber(x);
        let lastValue: ISerieValue<TX>;
        let lastXNum: number;
        for (const value of serie.values) {

            const curXNum = this.xAxis.valueToNumber(value.x);

            if (curXNum == xNum)
                return value.y;

            if (curXNum > xNum) {
                if (!lastValue)
                    return;
                return value.y + (value.y - lastValue.y) * (xNum - lastXNum) / (curXNum - lastXNum);
            }

            lastValue = value;
            lastXNum = xNum;
        }
    }

    /****************************************/

    getSeriePoint(viewPoint: IPoint, options: IChartRenderOptions): ISerieValue<TX> {

        const xMetric = options.metrics.x;
        const yMetric = options.metrics.y;

        const scale: IPoint = {
            x: options.viewRect.width / (xMetric.max - xMetric.min),
            y: options.viewRect.height / (yMetric.max - yMetric.min),
        }

        return {
            x: this.xAxis.numberToValue(xMetric.min + (viewPoint.x - options.viewRect.x) / scale.x),
            y: this.yAxis.numberToValue(yMetric.min + (options.viewRect.height + options.viewRect.y - viewPoint.y) / scale.y)
        }
    }

    /****************************************/

    getViewPoint(seriePoint: ISerieValue<TX>, options: IChartRenderOptions): IPoint {

        const xMetric = options.metrics.x;
        const yMetric = options.metrics.y;

        const scale: IPoint = {
            x: options.viewRect.width / (xMetric.max - xMetric.min),
            y: options.viewRect.height / (yMetric.max - yMetric.min),
        }

        return {
            x: options.viewRect.x + (this.xAxis.valueToNumber(seriePoint.x) - xMetric.min) * scale.x,
            y: options.viewRect.y + options.viewRect.height - ((this.yAxis.valueToNumber(seriePoint.y) - yMetric.min) * scale.y),
        }
    }

    /****************************************/

    protected updateActualMetrics() {

        this._actualMetrics = {
            x: {
                max: this.xAxis.range.max ?? this._globalMetrics.x.max,
                min: this.xAxis.range.min ?? this._globalMetrics.x.min
            },
            y: this.yAxis.rangeMode == "all" ? this._globalMetrics.y : this._globalMetrics.yw
        };

        switch (this.yAxis.rangeMode) {
            case "all":
                this._actualMetrics.y = this._globalMetrics.y;
                break;
            case "window":
                this._actualMetrics.y = this._globalMetrics.yw;
                break;
            case "manual":
                this._actualMetrics.y = {
                    min: this.yAxis.min,
                    max: this.yAxis.max,
                    decimals: this.yAxis.range.decimals
                }
                break;

        }

        let margin = 0;
        if (this._actualMetrics.y.max == this._actualMetrics.y.min) 
            margin = this._actualMetrics.y.min * 0.01;
        /*
        else
            margin = (this._actualMetrics.y.max - this._actualMetrics.y.min) * 0.01;*/

        this._actualMetrics.y.max += margin;
        this._actualMetrics.y.min -= margin;

        if (this._globalMetrics.y.decimals == 0)
            (this.yAxis as NumberChartAxis).decimals = 0;
    }

    /****************************************/

    protected drawYAxis(engine: IRenderEngine, options: IChartRenderOptions) {

        const axisLen = options.viewRect.height;
        const axis = this.yAxis;
        const valueRange = options.metrics.y.max - options.metrics.y.min;

        const labelStyle: ITextStyle = {
            fontFace: axis.label.style.fontFace,
            fill: axis.label.style.color,
            fontSize: axis.label.style.fontSize
        };

        let ticksCount: number;

        const fontHeight = axis.label.style.fontSize;

        if (!axis.ticks?.interval || axis.ticks.interval == "auto")
            ticksCount = axisLen / (fontHeight + minLabelMargin);
        else
            ticksCount = valueRange / axis.ticks.interval;

        ticksCount = Math.min(ticksCount, maxTicks);

        if (ticksCount - 1 <= 0)
            return;

        let tickMargin = valueRange / (ticksCount - 1)

        let curY = options.metrics.y.min;

        let maxLabelSize = 0;

        let ticks: {
            y: number,
            label: string,
            width: number,
            height: number
        }[] = [];

        const curX = this.xAxis.numberToValue(options.metrics.x.min);

        while (curY <= options.metrics.y.max + tickMargin / 2) {

            if (options.metrics.y.max - curY < tickMargin / 2)
                curY = options.metrics.y.max;

            const yValue = axis.numberToValue(curY);

            const label = axis.formatValue(yValue);

            const size = engine.measureText(label, labelStyle);

            maxLabelSize = Math.max(maxLabelSize, size.width);

            ticks.push({
                y: this.getViewPoint({ x: curX, y: yValue }, options).y,
                label: label,
                width: size.width,
                height: size.ascent
            });

            curY += tickMargin;
        }

        if (axis.label?.show) {
            for (const item of ticks)
                engine.drawText(item.label, {
                    x: Math.round(options.viewRect.x + (maxLabelSize - item.width) - labelAxisMargin),
                    y: Math.round(item.y + (item.height / 2) - 1)
                }, 0, labelStyle);

            maxLabelSize += labelAxisMargin;
            options.viewRect.width -= maxLabelSize;
            options.viewRect.x += maxLabelSize;
        }

        if (axis.ticks?.show || axis.showAxis) {
            const path = engine.createPath();

            if (axis.ticks?.show) {
                for (const item of ticks) {
                    path.moveTo(options.viewRect.x - tickSize, item.y - axis.ticks.style.width / 2);
                    path.lineTo(options.viewRect.x, item.y - axis.ticks.style.width / 2);
                }
            }

            if (axis.showAxis) {
                path.moveTo(options.viewRect.x, options.viewRect.y);
                path.lineTo(options.viewRect.x, options.viewRect.y + options.viewRect.height);

            }

            engine.drawPath(path, {
                stroke: axis.ticks.style.color,
                strokeWidth: axis.ticks.style.width,
                strokePattern: axis.ticks.style.pattern
            });
        }

        if (axis.grid?.show) {
            const path = engine.createPath();
            for (const item of ticks) {
                path.moveTo(options.viewRect.x, item.y);
                path.lineTo(options.viewRect.x + options.viewRect.width, item.y);
            }

            engine.drawPath(path, {
                stroke: axis.grid.style.color,
                strokeWidth: axis.grid.style.width,
                strokePattern: axis.grid.style.pattern
            });
        }
    }

    /****************************************/

    protected drawXAxis(engine: IRenderEngine, options: IChartRenderOptions) {
        const axisLen = options.viewRect.width;

        const axis = this.xAxis;
        const valueRange = options.metrics.x.max - options.metrics.x.min;

        //Prepare font
        const fontHeight = axis.label?.style.fontSize;

        const labelStyle: ITextStyle = {
            fontFace: axis.label.style.fontFace,
            fill: axis.label.style.color,
            fontSize: axis.label.style.fontSize
        };

        let ticksCount: number;

        //Compute AVG label width
        let curX = options.viewRect.x;
        let avgWidth = 0;
        for (var i = 0; i < maxTicks; i++) {

            const label = axis.formatValue(this.getSeriePoint({ x: curX, y: 0 }, options).x);
            const size = engine.measureText(label, labelStyle);
            avgWidth += size.width;  
            curX += axisLen / maxTicks;
        }
        avgWidth /= maxTicks;

        //Compute tick count
        let maxTicksForSpace = Math.floor(axisLen / (avgWidth + minLabelMargin));

        if (!axis.ticks?.interval || axis.ticks.interval == "auto")
            ticksCount = Math.floor(axisLen / (avgWidth + minLabelMargin));
        else
            ticksCount = Math.floor(valueRange / axis.ticks.interval) + 1;

        ticksCount = Math.min(Math.min(ticksCount, maxTicks), maxTicksForSpace);

        if (ticksCount - 1 <= 0)
            return;

        //Compute ticks
        let tickMargin = axis.nearestNumber(valueRange / (ticksCount - 1));

        let ticks: {
            x: number,
            label: string,
            width: number,
            height: number
        }[] = [];

        curX = this._actualMetrics.x.min;

        while (curX <= options.metrics.x.max + tickMargin / 2) {

            if (options.metrics.x.max - curX < tickMargin / 2)
                curX = options.metrics.x.max;

            const xValue = axis.numberToValue(curX);

            const label = axis.formatValue(xValue);
            const size = engine.measureText(label, labelStyle);

            ticks.push({
                x: this.getViewPoint({ x: xValue,  y: 0 }, options).x,
                label: label,
                width: size.width,
                height: size.ascent
            });

            curX += tickMargin;
        }

        if (ticks.length == 0)
            return;

        //Draw Label
        if (axis.label?.show) {

            const labelY = Math.round(options.viewRect.height + options.viewRect.y + fontHeight + labelAxisMargin + (axis.ticks?.show ? tickSize : 0));

            let lastX = options.viewRect.x;
            let maxX = lastX + options.viewRect.width;

            //First & Last1
            engine.drawText(ticks[0].label, {
                x: Math.round(lastX),
                y: labelY
            }, 0, labelStyle);

            if (ticks.length > 1) {
                const lastTick = ticks[ticks.length - 1];
                engine.drawText(lastTick.label, {
                    x: Math.round(options.viewRect.x + options.viewRect.width - lastTick.width),
                    y: labelY
                }, 0, labelStyle);

                maxX -= lastTick.width + minLabelMargin;
            }

            lastX += ticks[0].width + minLabelMargin;

            for (let i = 1; i < ticks.length - 1; i++) {

                const item = ticks[i];

                let labelX = item.x - item.width / 2;

                if (labelX < lastX || labelX + item.width > maxX)
                    continue;

                engine.drawText(item.label, { x: Math.round(labelX), y: labelY }, 0, labelStyle);

                lastX = labelX + item.width + minLabelMargin;
            }
        }

        //Draw Ticks / Axis
        if (axis.ticks?.show || axis.showAxis) {
            var path = engine.createPath();

            if (axis.ticks?.show) {
                for (const item of ticks) {
                    path.moveTo(item.x, options.viewRect.y + options.viewRect.height);
                    path.lineTo(item.x, options.viewRect.y + options.viewRect.height + tickSize);
                }
            }

            if (axis.showAxis) {
                path.moveTo(options.viewRect.x, options.viewRect.y + options.viewRect.height);
                path.lineTo(options.viewRect.x + options.viewRect.width, options.viewRect.y + options.viewRect.height);
            }

            engine.drawPath(path, {
                stroke: axis.ticks.style.color,
                strokeWidth: axis.ticks.style.width,
                strokePattern: axis.ticks.style.pattern
            });
        }

        //Draw Grid
        if (axis.grid?.show) {
            var path = engine.createPath();
            for (const item of ticks) {
                path.moveTo(item.x, options.viewRect.y);
                path.lineTo(item.x, options.viewRect.y + options.viewRect.height);
            }
            engine.drawPath(path, {
                stroke: axis.grid.style.color,
                strokeWidth: axis.grid.style.width,
                strokePattern: axis.grid.style.pattern
            });
        }
    }

    /****************************************/

    protected drawTitle(engine: IRenderEngine, options: IChartRenderOptions) {

        if (this.title.position != "top")
            throw new Error("Not supported");

        const lineHeight = this.title.style.fontSize;

        const height = engine.drawText(this.title.text, { x: options.viewRect.x, y: options.viewRect.y + lineHeight }, options.viewRect.width, {
            align: this.title.style.align,
            fill: this.title.style.color,
            fontFace: this.title.style.fontFace,
            fontSize: this.title.style.fontSize,
            wrap: true
        }) + lineHeight;

        options.viewRect.y += height;
        options.viewRect.height -= height;
    }

    /****************************************/

    protected drawLegend(engine: IRenderEngine, options: IChartRenderOptions) {

        if (this.legend.position != "bottom" || this.legend.orientation != "horizontal")
            throw new Error("Not supported");
    }

    /****************************************/

    protected draw(engine: IRenderEngine, options : IChartRenderOptions) {

        if (options.target != "zoom") {

            if (this.title.show && this.title.text)
                this.drawTitle(engine, options);

            if (this.legend.show && this.series.count > 0)
                this.drawLegend(engine, options);

            if (this.xAxis.label?.show)
                options.viewRect.height -= this.xAxis.label.style.fontSize + labelAxisMargin;

            if (this.xAxis.ticks?.show)
                options.viewRect.height -= tickSize;

            this.drawYAxis(engine, options);

            this.drawXAxis(engine, options);
        }

        engine.clipRect({
            ...options.viewRect,
            height: options.viewRect.height + 8,
            y: options.viewRect.y - 4
        });

        const drawOptions: IChartDrawerOptions<TX> = {
            ...options,
            serie: undefined,
            scale: {
                x: options.viewRect.width / (options.metrics.x.max - options.metrics.x.min),
                y: options.viewRect.height / (options.metrics.y.max - options.metrics.y.min)
            },
            viewTransform: a => this.getViewPoint(a, options),
        };


        for (const serie of this.series) {
            if (!serie.isVisible)
                continue;

            this.drawers[serie.type].draw(engine, {
                ...drawOptions,
                serie: serie
            });
        }
    }

    /****************************************/

    get container() {
        return this._container;
    }

    get metrics() {
        return this._actualMetrics;
    }

    readonly onZoomChanged = event();

    readonly onChanged = event();

    xAxis: IChartAxis<TX>;

    yAxis: IChartAxis<number>;

    padding: number;

    get legend() { return this._legend; }

    get title() { return this._title; }

    get actualMetrics() { return this._actualMetrics; }

    readonly drawers: { [key: string]: IChartDrawer } = {};

    readonly engine: IRenderEngine;

    readonly series = observableListOf<IChartSerie<TX>>();

}
 