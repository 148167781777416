﻿import { ITimeSerieTransform } from "../../TimeSerie/Abstraction/ITimeSerieTransform";
import { newProp, ItemsEditor, Format, registerType, EditableItemsSource, SelectableItemView, IItemsEditorConfig } from "../../WebApp";
import { TransformEditor } from "./TransformEditor";


interface ISerieTransformConfig extends IItemsEditorConfig<ITimeSerieTransform, ITimeSerieTransform, ITimeSerieTransform, ITimeSerieTransform> {

}

export class SerieTransformListEditor extends ItemsEditor<ITimeSerieTransform> {

    constructor(config?: ISerieTransformConfig) {
        super({
            canAdd: true,
            canRemove: true,
            editMode: "popup",
            saveOnCommit: false,
            isConfirmRemove: false,
            commitMode: "onchange",
            createItemView: a => new SelectableItemView({
                item: a,
                content: Format.title(a.name),
                isSelected: newProp("isSelected", a.isEnabled, value => {
                    a.isEnabled = value;
                    this.notifyEditValueChangedAsync();
                })
            }),
            createItemAddEditor: () => new TransformEditor(),
            itemsSource: new EditableItemsSource({
                displayName: "transform"
            }),
            ...config
        });
    }
}


registerType(SerieTransformListEditor, "SerieTransformView");
