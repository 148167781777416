﻿import { Services, IDictionary } from "../../WebApp";
import { ITimeSerie } from "../Abstraction/ITimeSerie";
import { ITimeSerieFilter } from "../Abstraction/ITimeSerieFilter";
import { ISerieInfo } from "../Abstraction/ITimeSerieInfo";
import { ITimeSerieSource } from "../Abstraction/ITimeSerieSource";


export class MultipleSerieSource implements ITimeSerieSource {

    protected _infoMap: IDictionary<ISerieInfo>;

    constructor() {


        for (const source of Services.timeSerieSources)
            this.sources.push(new source());
    }

    async listSeriesAsync(): Promise<ISerieInfo[]> {

        this._infoMap = {};

        const result: ISerieInfo[] = [];

        for (const source of this.sources) {

            const items = await source.listSeriesAsync();

            for (const item of items) 
                this._infoMap[item.name] = item;
            
            result.push(...items);
        }

        return result;
    }

    async getSerieInfoAsync(name: string): Promise<ISerieInfo> {

        return await this.getInfoAsync(name);
    }

    async getSerieAsync(name: string, filter?: ITimeSerieFilter): Promise<ITimeSerie> {

        return (await this.getInfoAsync(name)).source.getSerieAsync(name, filter);
    }

    async updateSerieAsync(name: string) {

        return (await this.getInfoAsync(name)).source.updateSerieAsync(name);
    }

    protected async getInfoAsync(name: string) {

        if (!this._infoMap)
            await this.listSeriesAsync();

        return this._infoMap[name];
    }

    readonly sources: ITimeSerieSource[] = [];

    readonly name: string = "Computed";
}