﻿import { StateObject } from "../../StateObject";
import { LocalString, ITypedState  } from "../../WebApp";
import { ITimeSerie } from "./../Abstraction/ITimeSerie";
import { ITimeSerieTransform } from "./../Abstraction/ITimeSerieTransform";

export abstract class BaseTimeSerieTransform<TState extends ITypedState = ITypedState> extends StateObject<TState> implements ITimeSerieTransform {

    abstract applyAsync(input: ITimeSerie[]): Promise<ITimeSerie[]>;

    isEnabled: boolean = true;

    get baseName() { return this.name; }

    abstract readonly name: LocalString;
}