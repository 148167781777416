﻿import { IPath2D } from "../Abstraction/IRenderEngine";

export class Path2DInt implements IPath2D {

    constructor() {
        this.path = new Path2D();
    }

    /****************************************/

    lineTo(x: number, y: number): void {
        this.path.lineTo(this.trans(x), this.trans(y));
    }

    /****************************************/

    moveTo(x: number, y: number): void {
        this.path.moveTo(this.trans(x), this.trans(y));
    }

    /****************************************/

    circle(x: number, y: number, radius: number) {

        this.path.moveTo(x + radius, y);
        this.path.arc(this.trans(x), this.trans(y), this.trans(radius), 0, Math.PI * 2);
    }

    /****************************************/

    trans(value: number) {
        return Math.round(value);
    }

    /****************************************/

    pathData: string;

    path: Path2D;
}