﻿import { IPath2D } from "../Abstraction/IRenderEngine";

export class SvgPath implements IPath2D {

    constructor() {
        this.pathData = "";
    }

    /****************************************/

    lineTo(x: number, y: number): void {
        this.pathData += `L ${x} ${y} `;
    }

    /****************************************/

    moveTo(x: number, y: number): void {
        this.pathData += `M ${x} ${y} `;
    }

    /****************************************/

    circle(x: number, y: number, radius: number) {

        this.pathData +=
            `M ${x} ${y} ` +
            `m ${-radius} 0 ` +
            `a ${radius} ${radius} 0 1 0 ${radius * 2} 0 ` +
            `a ${radius} ${radius} 0 1 0 ${-radius * 2} 0 `;
    }

    /****************************************/

    pathData: string;
}