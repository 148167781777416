﻿import { ITimeSerieTransform } from "../../TimeSerie/Abstraction/ITimeSerieTransform";
import { FunctionSerieTransform } from "../../TimeSerie/Transforms/FunctionSerieTransform";
import { BindableObject, ContentView, Format, getTypeName, IEditor, IItemEditor, isEditable, ItemsSource, linq, Panel, registerType, Services, SingleItemSelector } from "../../WebApp";


export class TransformEditor extends BindableObject implements IItemEditor<ITimeSerieTransform> {

    constructor() {

        super();

        const panel = new Panel({
            name: getTypeName(this),
            styles: [""]
        });

        panel.addView(new SingleItemSelector({
            template: "ItemSelectorList",
            allowNull: false,
            styles: ["surface"],
            value: this.prop("value"),
            itemsSource: new ItemsSource({
                getItemsAsync: async () => linq(Services.timeSerieFunctions)
                    .select(a => new FunctionSerieTransform(new a()) as ITimeSerieTransform)
                    .union(linq(Services.timeSerieTransforms).select(a => new a()).toArray())
                    .toArray(),
                getItemText: a => Format.title(a.baseName)
            })
        }));

        panel.addView(new ContentView({
            content: this.prop("editor")
        }));

        this.view = panel;

        this.prop("value").subscribe(async value => {

            if (isEditable(value)) {
                this.editor = value.createEditor();
                if (this.editor)
                    await this.editor.loadAsync();
            }
            else
                this.editor = null;
        });
    }

    /****************************************/

    async beginEditAsync(item: ITimeSerieTransform) {

        this.value = item;
    }

    /****************************************/

    async commitAsync(force?: boolean) {
        if (!this.editor)
            return true;
        return await this.editor.commitAsync();
    }

    /****************************************/

    readonly isValid = true;

    readonly view: Panel;

    editor: IEditor<ITimeSerieTransform, any>;

    value: ITimeSerieTransform;
}

registerType(TransformEditor, "TransformEditor"); 