﻿import { registerType } from "../../WebApp";
import { ITimeSerieValue } from "../Abstraction/ITimeSerieValue";
import { BaseTimeSerieFunction } from "./BaseTimeSerieFunction";

export class IntegrateFunction extends BaseTimeSerieFunction{

    constructor() {
        super();
    }

    /****************************************/

    async applyAsync(serie: ITimeSerieValue[]): Promise<ITimeSerieValue[]> {

        const result: ITimeSerieValue[] = [];

        let curValue = 0;

        for (const value of serie) {
            curValue += value.value;
            result.push({
                date: value.date,
                value: curValue
            });
        }

        return result;
    }

    /****************************************/

    readonly name = "integrate";
}

registerType(IntegrateFunction, "IntegrateFunction");