﻿import { INumberChartAxisConfig, NumberChartAxis } from "./NumberChartAxis";

export class PercentageChartAxis extends NumberChartAxis {

    constructor(config?: INumberChartAxisConfig) {

        super({
            interval: 0.01,
            decimals: 1,
            ticks: {
                interval: 0.01
            },
            ...config
        })
    }

    /****************************************/

    formatValue(value: number): string {
        return super.formatValue(value * 100) + "%"; 
    }

}
