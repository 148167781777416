﻿import { StateObject } from "../../StateObject";
import { logProgress } from "../../Utils";
import { Format, ITypedState, linq, Services, truncateTime, createInstance, getTypeName, registerType } from "../../WebApp";
import { ITimeSerieFilter, ITimeSerieFilterDimension } from "../Abstraction/ITimeSerieFilter";
import { ITimeSerieProvider, ITimeSerieSet } from "../Abstraction/ITimeSerieProvider";
import { ITimeSerieTransform } from "../Abstraction/ITimeSerieTransform";
import { ConcatSerieTransform } from "../Transforms/ConcatSerieTransform";

export type DimensionAggregtionMode = "sum" | "serie";

interface IDimensionData {

    name: string;

    values: { id: number, name: string }[];
}


export interface IDimensionFilter {

    dimensionName: string;

    mode: DimensionAggregtionMode;

    values: number[];

    excluded: number[];
} 

export interface IBasicSerieState extends ITypedState {

    filters: IDimensionFilter[];

    transforms: ITypedState[];

    factorName: string;

    serieName: string;
}

export class BasicSerieProvider extends StateObject implements ITimeSerieProvider {


    async getSeriesAsync(): Promise<ITimeSerieSet> {

        const result: ITimeSerieSet = {
            name: "",
            series: []
        }

        if (!this.serieName)
            return result;

        const cross: IDimensionData[] = [];

        const filter: ITimeSerieFilter = {
            dimensions: [],
        }

        const transform = new ConcatSerieTransform(this.transforms);

        const info = await Services.data.getSerieInfoAsync(this.serieName);

        const addResultAsync = async (filter?: ITimeSerieFilter, displayName?: string) =>  {

            filter = { toDate: truncateTime(new Date()), ...filter };

            const serie = await Services.data.getSerieAsync(this.serieName, filter);

            const serieList = await transform.applyAsync([{
                name: displayName ?? this.serieName,
                values: serie.values,
                source: serie.source
            }]);

            if (this.factorName) {

                const dimensions = [...filter.dimensions];

                const index = dimensions.findIndex(a => a.name == this.factorName);
                if (index != -1) 
                    dimensions.splice(index, 1);

                const factorDimFilter = this.filters.find(a => a.dimensionName == this.factorName);
                if (factorDimFilter?.excluded?.length > 0) {

                    const allDimensions = info.dimensions.find(a => a.name == this.factorName).values.map(a => a.id);

                    for (const excluded of factorDimFilter.excluded)
                        allDimensions.splice(allDimensions.indexOf(excluded), 1);

                    dimensions.push({
                        name: this.factorName,
                        values: allDimensions
                    });

                }

                const factorFilter = {
                    ...filter,
                    dimensions
                }

                const factorSerie = await Services.data.getSerieAsync(this.serieName, factorFilter);

                const factorSerieList = await transform.applyAsync([{
                    name: displayName ?? this.serieName,
                    values: factorSerie.values,
                    source: factorSerie.source
                }]);

                serieList.forEach((curSerie, i) => {

                    let fi = 0;

                    const curFactorSerie = factorSerieList[i];

                    for (let i = 0; i < curSerie.values.length; i++) {

                        while (new Date(curFactorSerie.values[fi].date as string) < new Date(curSerie.values[i].date as string) && fi < curFactorSerie.values.length)
                            fi++;

                        if (curFactorSerie.values[fi].date == curSerie.values[i].date && curFactorSerie.values[fi].value != 0)
                            curSerie.values[i].value /= curFactorSerie.values[fi].value;
                    }
                });
            }
         
            result.series.push(...serieList);
        }

        result.name = this.serieName;

        for (const dimFilter of this.filters) {

            const dimension = info.dimensions.find(a => a.name == dimFilter.dimensionName);

            if (dimFilter.mode != "serie" || !dimFilter.values || dimFilter.values.length <= 1) {
                if (dimFilter.values && dimFilter.values.length > 0 && dimFilter.values.length < dimension.values.length) {
                    filter.dimensions.push({
                        name: dimension.name,
                        values: dimFilter.values
                    });
                    result.name += " (" + Format.text(dimension.name) + ": ";

                    for (let i = 0; i < dimFilter.values.length; i++) {
                        const value = linq(dimension.values).first(b => b.id == dimFilter.values[i])?.displayName;
                        if (i > 0)
                            result.name += ", ";
                        result.name += Format.text(value);
                    }

                    result.name += ")";
                }
            }
            else {
                cross.push({
                    name: dimension.name,
                    values: linq(dimFilter.values).where(a => a != null).select(a => ({
                        id: a,
                        name: linq(dimension.values).first(b => b.id == a).displayName
                    })).toArray()
                });
            }
        }

        await logProgress(async () => {
            if (cross.length > 0) {

                const index = [];
                const dimensions: ITimeSerieFilterDimension[] = [];
                for (let i = 0; i < cross.length; i++) {
                    index.push(0);
                    dimensions.push({
                        name: cross[i].name,
                        values: [null]
                    });
                }

                let isOver = false;

                while (!isOver) {

                    const curFilter = {
                        ...filter,
                        dimensions: [...filter.dimensions, ...dimensions]
                    };

                    let name = "";

                    for (let i = 0; i < cross.length; i++) {
                        if (cross[i].values.length > 1)
                            name += " (" + cross[i].name + ": " + cross[i].values[index[i]].name + ")";
                        dimensions[i].values[0] = cross[i].values[index[i]].id;
                    }

                    await addResultAsync(curFilter, name);

                    let curI = index.length - 1;

                    while (curI >= 0) {

                        if (index[curI] == cross[curI].values.length - 1) {
                            if (curI == 0) {
                                isOver = true;
                                break;
                            }
                            index[curI] = 0;
                            curI--;
                        }
                        else {
                            index[curI]++;
                            break;
                        }
                    }
                }
            }
            else
                await addResultAsync(filter);

        }, "Adding...");

        return result;
    }

    /****************************************/

    filters: IDimensionFilter[] = [];

    transforms: ITimeSerieTransform[] = [];

    factorName: string = null;

    serieName: string = null;
}

registerType(BasicSerieProvider, "BasicSerieProvider");