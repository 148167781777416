﻿import { IChart } from "../Chart/Abstraction/IChart";
import { TimeChartAxis } from "../Chart/Axis/TimeChartAxis";
import { parseDate, saveFileAsync, Drawer, IView, IViewContent, IViewContentHost, IViewContentInfo, IViewContentProvider, Services,  IAction, Interaction, linq, ITypedState } from "../../WebApp";
import { ChartView } from "../Components/ChartView";
import { BasicSerieEditor } from "../Components/BasicSerieEditor";
import { SerieTransformListEditor } from "../Components/SerieTransformListEditor";
import { MainActivity } from "../MainActivity";
import { PercentageChartAxis } from "../Chart/Axis/PercentageChartAxis";
import { NumberChartAxis } from "../Chart/Axis/NumberChartAxis";
import { BasicSerieProvider } from "../../TimeSerie/Providers/BasicSerieProvider";
import { sha256 } from "../../Utils";
import { SerieProviderItem } from "../../Project/SerieProviderItem";
import { ChartStatsView } from "../Components/ChartStatsView";
import { TimeUnit } from "../../TimeSerie/Abstraction/Types";
import { TimeSerieUnit } from "../../TimeSerie/Abstraction/ITimeSerieInfo";


export function toChartTimeUnit(value: TimeSerieUnit): TimeUnit {
    switch (value) {
        case TimeSerieUnit.Day:
            return "day";
        case TimeSerieUnit.Week:
            return "week";
        case TimeSerieUnit.Month:
            return "month";
        case TimeSerieUnit.Year:
            return "year";
    }
}

export class SerieViewContent implements IViewContentProvider {

    protected _palette = ["#e53935", "#8e24aa", "#1e88e5", "#00897b", "#7cb342", "#fdd835", "#6d4c41", "#546e7a", "#d81b60", "#5e35b1", "#00acc1", "#43a047", "#c0ca33", "#ffb300", "#424242"];   
    protected _chart: IChart<Date>;
    protected _updating = 0;
    protected _providerEditor: BasicSerieEditor;

    async getContentAsync(host: IViewContentHost): Promise<IViewContent> {

        const views: IView[] = [];

        //Chart View
        const chartView = new ChartView();
        chartView.chart.xAxis = new TimeChartAxis({ unit: "day" })
        Services.activeChart = chartView.chart;
        this._chart = chartView.chart;
        views.push(chartView);

        //Transform
        const transformEditor = new SerieTransformListEditor();
        views.push(transformEditor);

        //Transform
        const startsView = new ChartStatsView();
        views.push(startsView);

        this._chart.onZoomChanged.add(() => {
            startsView.update();
            const proj = Services.projectManager.activeProject;
            proj.viewOptions = {
                ...proj.viewOptions,
                range: {
                    min: this._chart.xAxis.min,
                    max: this._chart.xAxis.max
                }
            }
            Services.projectManager.saveProjectAsync();
        });

        //Serie Provider
        this._providerEditor = new BasicSerieEditor(transformEditor);
        await this._providerEditor.view.loadAsync();

        this._providerEditor.prop("value").subscribe(async value => {

            await this.updateChartAsync(chartView.chart, value);
            startsView.update();
        });

        if (screen.width > 980)
            views.push(this._providerEditor.view);

        else {

            if (host instanceof MainActivity)
                (host.menu as Drawer).header = this._providerEditor.view;

            screen.orientation.lock("landscape");
        }
        
        let project = await Services.projectManager.loadProjectAsync();

        let serieProvider: BasicSerieProvider;

        if (project?.items?.count == 1 &&
            project.items.get(0) instanceof SerieProviderItem &&
            (project.items.get(0) as SerieProviderItem).provider instanceof BasicSerieProvider) {
            serieProvider = (project.items.get(0) as SerieProviderItem).provider as BasicSerieProvider;
        }
        else {
            serieProvider = new BasicSerieProvider();
            project = Services.projectManager.newProject();
            project.items.add(new SerieProviderItem({ provider: serieProvider }));
        }

        if (project.viewOptions?.range) {
            this._chart.xAxis.min = parseDate(project.viewOptions.range.min);
            this._chart.xAxis.max = parseDate(project.viewOptions.range.max);
        }

        await this._providerEditor.beginEditAsync(serieProvider);
        await this.updateChartAsync(chartView.chart, serieProvider)

        //Actions
        const actions: IAction[] = [{
            name: "copy-data",
            icon: "fas fa-copy",
            executeAsync: async () => this.copyChartData()
        }, {
            name: "copy-png",
            icon: "fas fa-image",
            executeAsync: async () => this.copyChartImage()
        }, {
            name: "download",
            icon: "fas fa-download",
            executeAsync: async () => { },
            subActions: [{
                name: "svg",
                icon: "fas fa-file-image",
                executeAsync: () => this.downloadSvg()
            }, {
                name: "csv",
                icon: "fas fa-file-csv",
                executeAsync: () => this.downloadCsv()
            }]
        }, { 
            name: "copy-link",
            icon: "fas fa-link",
            executeAsync: async () => this.copyShareLinkAsync()
        }];

        return {
            title: "data-lab",
            views,
            actions,
            styles: ["surface"]
        } as IViewContent;
    }

    /****************************************/

    async updateChartAsync(chart: IChart<Date>, provider: BasicSerieProvider) {

        if (this._updating)
            return;

        this._updating++;

        chart.beginUpdate();

        chart.series.clear();

        try {

            const serieSet = await provider.getSeriesAsync();

            for (const serie of serieSet.series) {

                chart.series.add({
                    name: serie.name,
                    type: "lines",
                    style: {
                        borderWidth: 1.5,
                        borderColor: this._palette[(Services.activeChart.series.count * 1) % this._palette.length],
                        pointSize: 2
                    },
                    isVisible: true,
                    values: linq(serie.values).select(a => ({
                        x: new Date(a.date),
                        y: a.value
                    })).toArray()
                })
            }

            chart.title.text = serieSet.name;

            if (provider.factorName)
                chart.yAxis = new PercentageChartAxis({ decimals: 1 });
            else
                chart.yAxis = new NumberChartAxis({ decimals: 1 });

            if (provider.serieName) {
                var info = await Services.data.getSerieInfoAsync(provider.serieName);
                (chart.xAxis as TimeChartAxis).unit = toChartTimeUnit(info.timeUnit);
            }

            chart.adjustAxisRanges();

        }
        finally {
            this._updating--;

            chart.endUpdate();
        }

        (Services.projectManager.activeProject.items.get(0) as SerieProviderItem).provider = provider;
        await Services.projectManager.saveProjectAsync();
    }

    /****************************************/

    async downloadSvg() {

        const image = await this._chart.getImageAsync({
            format: "svg",
            background: "#fff",
            padding: 16
        });

        await saveFileAsync(image);
    }

    /****************************************/

    async downloadCsv() {

        const table = this._chart.getTable({ fullX: false });

        const file = new File([this.exportDataTable(table, ",")], "data.csv", { type: "text/csv" });

        await saveFileAsync(file);
    }

    /****************************************/

    async copyChartImage() {

        const image = await this._chart.getImageAsync({
            format: "png",
            background: "#fff",
            padding: 16
        });

        await navigator.clipboard.write([new ClipboardItem({
            "image/png": image
        }, {
            presentationStyle: "inline"
        })]);

        Interaction.info("msg-image-copied");
    }

    /****************************************/

    async copyShareLinkAsync() {

        const link = await this.createShareLinkAsync();

        await navigator.clipboard.writeText(link);

        Interaction.info("msg-link-copied");
    }

    /****************************************/

    protected async createShareLinkAsync() {

        const proj = Services.projectManager.activeProject.getState();
        const resId = await sha256(JSON.stringify(proj));

        await Services.serverStorage.setItem(resId, proj);

        const params = new URLSearchParams(window.location.search);
        params.set("project", resId);

        let url = window.location.href;

        let searchIndex = window.location.href.indexOf("?");

        if (searchIndex != -1)
            url = url.substring(0, searchIndex);

        url += "?" + params.toString();

        return url;
    }

    /****************************************/

    protected exportDataTable(table: string[][], sep: string) {

        let text = "";
        for (const row of table) {
            for (let i = 0; i < row.length; i++) {
                if (i > 0)
                    text += sep;
                text += row[i] ?? "";
            }
            text += "\n";
        }
        return text;
    }

    /****************************************/

    async copyChartData(fullX = true) {

        const table = this._chart.getTable({ fullX });

        await navigator.clipboard.writeText(this.exportDataTable(table, "\t"));

        Interaction.info("msg-serie-copied");
    }

    /****************************************/

    readonly info: IViewContentInfo = {
        name: "serie-view"
    }

}