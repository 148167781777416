﻿import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { event, IOperation, Services } from "../WebApp";

export enum LogLevel {
    Error,
    Warning,
    Info,
    Debug,
    Trace
}

export interface ILogEventArgs {
    text: string;
    level: LogLevel;
}

/****************************************/

export class LogService {

    protected _connection: HubConnection;

    async startAsync() {

        this._connection = new HubConnectionBuilder()
            .withUrl("/log")
            .withAutomaticReconnect()
            .build();

        this._connection.on("Log", (text: string, level: LogLevel) => {

            this.onLog.raise(this, { text, level });

            if (this.activeOperation)
                this.activeOperation.progress = {
                    message: text
                };
        });

        const next = Services.httpClient.onNewRequest;

        Services.httpClient.onNewRequest = req => {

            if (!req.headers)
                req.headers = {};
            req.headers["Log-Connection-Id"] = this._connection.connectionId;
            if (next)
                next(req);
        };

        await this._connection.start();
    }

    /****************************************/

    activeOperation: IOperation;

    readonly onLog = event<ILogEventArgs>();

}