﻿import { formatDate, TimeSpan } from "../../../WebApp";
import { BaseChartAxis } from "./BaseChartAxis";
import { IChartAxisConfig } from "../Abstraction/IChartAxis";

type TimeUnit = "day" | "week" | "month" | "year";

export interface ITimeChartAxisConfig extends IChartAxisConfig<Date> {

    unit?: TimeUnit;
}

export class TimeChartAxis extends BaseChartAxis<Date>  {

    constructor(config?: ITimeChartAxisConfig) {
        super({
            ticks: {
                interval: 1
            },
            label: {
                show: true
            },
            unit: "day",
            ...config
        });

        this.unit = config?.unit ?? "day";
    }

    /****************************************/

    formatValue(value: Date): string {

        if (!value)
            return "";

        switch (this.unit) {
            case "day":
                if (value.getFullYear() <= 1)
                    return formatDate(value, "{DD} {MMM}")
                return formatDate(value, "{DD}/{MM}/{YYYY}")
            case "month":
                if (value.getFullYear() <= 1)
                    return formatDate(value, "{MMM}")
                return formatDate(value, "{MMM} {YYYY}")
        }
    }

    /****************************************/

    numberToValue(value: number): Date {

        if (isNaN(value) || value == Number.POSITIVE_INFINITY || value == Number.NEGATIVE_INFINITY)
            return null;

        value = this.nearestNumber(value);

        switch (this.unit) {
            case "day":
                return new Date(TimeSpan.fromDays(value).ticks);
            case "month":
                const year = Math.floor(value / 12) + 1;
                const month = value % 12;
                const date = new Date(year, month, 1);
                date.setFullYear(year);
                return date;
        }
    }

    /****************************************/

    valueToNumber(value: Date): number {

        if (!value)
            return null;

        switch (this.unit) {
            case "day":
                return this.nearestNumber(new TimeSpan(value.getTime()).totalDays);
            case "month":
                return (value.getFullYear() - 1) * 12 + value.getMonth();
            case "year":
                return value.getFullYear();
        }
    }

    /****************************************/

    unit: TimeUnit;
}
